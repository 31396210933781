.bilic-article-template {
  &-bv {
    .bilic-global-header__inner {
      background: transparent;
      border-bottom: 1px solid rgba(var(--theme-black-rgb), .1);

      .bilic-global-header__navigation {

        &__logo {
          color: var(--theme-black);
        }

        &__link {
          a {
            color: var(--theme-black);
          }
        }

        &__socials {
          ul.nav li.nav-item a.nav-link {
            border-color: rgba(var(--theme-black-rgb), .9);

            i {
              color: rgba(var(--theme-black-rgb), .9);
            }
          }
        }

        &__btn .btn {
          color: rgba(var(--theme-black-rgb), 0.8);
          border: 1px solid rgba(var(--theme-black-rgb), 0.9);
        }
      }
    }

    .bilic-article-template__hero {
      padding: 16rem 0 16rem;
      min-height: 80vh;

      @media screen and (max-width: 760px) {
        padding: 12rem 1rem 6rem;
        min-height: 74vh;
      }

      &__background {

        &-bv {
          object-fit: contain;
          width: 53%;
          position: absolute;
          right: -36px;
          top: 95px;
          z-index: 12;

          @media screen and (max-width: 760px) {
            display: none;
          }

          @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            width: 44%;
            right: -86px;
            bottom: -117px;
            top: initial;
          }


          @media only screen and (min-width: 1400px) {
            width: 40%;
            right: 213px;
            top: 155px;
          }

        }
      }

      &__text {

        // align-items: center;
        // text-align: center;
        h5 {
          margin: 24px 0 8px;

          @media screen and (max-width: 760px) {

            font-size: 56px;
            line-height: 60px;
          }
        }

        p {
          color: var(--black);
          max-width: 556px;

          @media screen and (max-width: 760px) {

            line-height: 32px;
          }

          @media only screen and (min-width: 1400px) {
            max-width: 588px;
          }
        }

      }

      &__links {
        &__btn {
          border: 1px solid var(--theme-primary) !important;
          background: var(--theme-primary) !important;

          &-alt {
            background: transparent !important;
            color: var(--theme-black) !important;
            border: 1px solid var(--theme-black) !important
          }
        }
      }
    }

    .bilic-article-template__content {
      &__tagline {
        header {
          h4 {
            max-width: 600px;
          }

        }
      }

      &__solutions {

        &__list {
          grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));

          @media screen and (max-width: 760px) {
            grid-template-columns: 1fr;
          }
        }
      }

      &__teaser {
        &__illustration {
          display: flex;
          align-items: center;
          justify-content: center;

          @media screen and (max-width: 760px) {
            margin-block-start: 20px;
          }
        }
      }
    }
  }

}


.bilic-article-template__glass {
  box-shadow: hsla(107, 73%, 91%, 0.431) 0px 4px 12px 0px;
  position: absolute;
  border-radius: 50%;
  z-index: 11;
  backdrop-filter: blur(2px);
  filter: blur(2px);
  box-shadow: inset 0 0 2000px hsla(0, 0%, 100%, 0.2),
    0 3px 8px hsla(0, 0%, 0%, 0.02);

  &-one {
    bottom: -116px;
    width: 500px;
    height: 500px;
    background: hsla(103, 100%, 93%, 0.522);
    right: 27%;
  }

  &-two {
    bottom: -62px;
    width: 680px;
    height: 680px;
    background: hsla(103, 100%, 93%, 0.451);
    right: 6%;
  }
}