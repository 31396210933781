.bilic-global-footer {
  .container {
    max-width: 1140px;
  }

  width: 100%;
  padding: 5rem 0 4rem;
  background: var(--black);
  min-height: 320px;
  position: relative;
  color: #fff;

  &__brand {
    &__logo {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 40%;
      }

      h3 {
        font-family: "Comfortaa",
          sans-serif;
        font-weight: 700;
        font-size: 2.4rem;
        color: #fff;
      }
    }

    p {
      font-size: 1.6rem;
      margin-top: 0.4rem;
      max-width: 272px;
      line-height: 28px;
      font-family: var(--font-primary);

      &.copyright {
        font-size: 1.4rem;
        opacity: .7;
      }
    }
  }

  &__links {
    margin-top: 3rem;

    h2 {
      margin-bottom: 2rem;
      font-size: 2rem;
      font-family: var(--font-heading-grotesk);
      font-weight: bold;
      color: var(--theme-primary);
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        margin-bottom: 1.2rem;

        a {
          font-size: 1.6rem;
          display: flex;
          align-items: center;
          grid-gap: 10px;
          gap: 10px;
          text-decoration: none;
          color: inherit;
          opacity: 0.7;
          line-height: 28px;
          font-family: var(--font-primary);
        }
      }
    }
  }
}