html {
  font-size: 62.5%;
  height: 100%;

  @include screen(small) {
    font-size: 58%;
  }
}


body {
  @include sexyfont;
  font-family: var(--font-primary);
  color: rgba(var(--theme-black-rgb), 1);
  height: 100%;
  position: relative;
  font-size: 1.6rem;
  background: #f3f4f5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-heading);
  color: rgba(var(--theme-black-rgb), 1);
  overflow-wrap: break-word;
  line-height: 1.25;
}

p {
  --font-size: 1.6rem;
  --line-height: calc(var(var(--font-size)* 1.5));
  font-family: var(--font-primary);
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: 400;
}

a {
  color: rgba(var(--theme-black-rgb), 1);
  font-family: var(--font-primary);
  text-decoration: none;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    opacity: .7;
    color: inherit;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    white-space: nowrap;
    border-radius: 20px;
    border: none;
    font-family: var(--font-heading);
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 150ms linear,
      color 150ms linear;

  }
}

a,
input,
button,
textarea,
select {
  font: inherit;

}