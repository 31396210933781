.bilic-article-template {
  &-pr {

    .bilic-global-header__inner {
      background: transparent;
      border-bottom: 1px solid rgba(var(--theme-black-rgb), .1);

      .bilic-global-header__navigation {

        &__logo {
          color: var(--theme-black);
        }

        &__link {
          a {
            color: var(--theme-black);
          }
        }

        &__socials {
          ul.nav li.nav-item a.nav-link {
            border-color: rgba(var(--theme-black-rgb), .9);

            i {
              color: rgba(var(--theme-black-rgb), 1);
            }
          }
        }

        &__btn .btn {
          color: rgba(var(--theme-black-rgb), 0.8);
          border: 1px solid rgba(var(--theme-black-rgb), 0.9);
        }
      }
    }

    .bilic-article-template__hero {
      justify-content: center;
      padding: 10rem 0 16rem;

      @media screen and (max-width: 760px) {
        padding: 12rem 1rem 6rem;
        min-height: 74vh;
      }

      @media only screen and (min-width: 1400px) {
        min-height: 76vh;
        padding: 16rem 0 14rem;
      }

      &__background {
        position: absolute;
        width: 84%;
        top: 30px;
        opacity: .2;

        @media screen and (max-width: 760px) {
          width: 157%;
          top: 30px;
          opacity: .6;
        }



        &-imp {
          object-fit: contain;
          width: 52%;
          position: absolute;
          right: 24%;
          top: 496px;
          opacity: 1;

          @media screen and (max-width: 760px) {
            display: none;
          }

          @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            width: 44%;
            right: -86px;
            bottom: -117px;
            top: initial;
          }


          @media only screen and (min-width: 1400px) {
            width: 57%;
            right: 21%;
            top: 512px;
          }

        }
      }

      &__text {
        text-align: center;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 760px) {
          text-align: left;
          justify-content: flex-start;
          align-items: flex-start;
        }

        h5 {
          font-size: 8.4rem;
          line-height: 100px;
          color: var(--theme-black);
          margin: 24px 0 4px;

          @media screen and (max-width: 760px) {
            font-size: 64px;
            line-height: 70px;
            margin-bottom: 2rem;
          }
        }

        p {
          font-size: 2.0rem;
          max-width: 700px;
          line-height: 3.5rem;
          color: rgba(var(--theme-black-rgb), .9);

        }
      }

      &__links {
        margin: 1rem 0 1.2rem;

        &__btn {
          border: 1px solid var(--theme-primary) !important;
          background: var(--theme-primary) !important;

          &-alt {
            background: transparent !important;
            color: var(--theme-black) !important;
            border: 1px solid var(--theme-black) !important
          }
        }


      }
    }

    .bilic-article-template__content {
      padding: 4rem 0 0;

      &__tagline {
        header {


          // h4 {}

          p {
            font-size: 1.8rem;
            max-width: 880px;
            line-height: 30px;
          }
        }
      }

      &__features {
        margin: 2.4rem 0 12rem;

      }

      &__solutions {
        header {
          h4 {
            max-width: 560px;
          }
        }

        &__list {
          grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));

          @media screen and (max-width: 760px) {
            grid-template-columns: 1fr;
          }
        }
      }

      &__teaser {
        &__illustration {
          display: flex;
          align-items: center;
          justify-content: center;

          @media screen and (max-width: 760px) {
            margin-block-start: 20px;
          }

          img {
            max-width: 39%;
            position: absolute;
            top: 154px;
            right: 104px;

            @media only screen and (min-width: 1400px) {
              max-width: 37%;
              position: absolute;
              top: 115px;
              display: flex;
              right: 228px;
            }

          }
        }

        &-righttext {

          img {
            right: initial;
            max-width: 39%;
            left: 110px;

            @media screen and (max-width: 760px) {}

            @media only screen and (min-width: 1400px) {
              right: initial;
              left: 190px;
              max-width: 34%;
            }

          }

        }
      }

      &__usecase {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 6rem;

        h5 {
          margin-bottom: 1.4rem;
          letter-spacing: -.9px;
          z-index: 300;
          font-size: 68px;
          font-weight: 300;
          line-height: 92px;
          margin-top: 2.5rem;
          max-width: 900px;

          @media screen and (max-width: 760px) {

            font-size: 63px;
            line-height: 70px;
          }

          span {
            span {
              background: linear-gradient(90deg, #44962d, #5cc23a 25.17%, #208b4a 50%, #1c703d 73.09%, #208b4a);
              -webkit-background-clip: text;
              background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }

        p {
          margin: 0.4rem 0 0.5rem;
          font-size: 20px;
          max-width: 740px;
          line-height: 36px;
        }
      }
    }

    .bilic-article-template__curve {
      display: none !important;
    }
  }
}