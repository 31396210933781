.bilic-global-header__navigation__link {
    &__dropdown {
        position: relative;

        .sub-header {
            color: #857a8e;
            padding-right: 6px;
            font-size: 14px;
        }

        a {
            position: relative;
        }

        // &:hover &__wrap::before,
        // &:focus-within &__wrap::before {
        //     content: "";
        //     position: absolute;
        //     top: -8px;
        //     left: calc(60% - 9px);
        //     width: 2rem;
        //     height: 2rem;
        //     transform: translateX(calc(-50% - 24.0312px)) rotate(45deg);
        //     background: var(--theme-black);
        //     will-change: transform;
        //     transition-property: transform;
        //     z-index: 30;
        //     border-radius: 2px;

        //     @media only screen and (max-width: 760px) {
        //         left: calc(88% - 9px);
        //     }

        //     @media only screen and (min-width: 1400px) {
        //         left: calc(60% - 16px);
        //     }
        // }

        &__wrap {
            background: #040802f0;
            padding: 1.2rem 2.4rem 2.4rem;
            box-shadow: 0px 10px 20px #0000001a;
            border-radius: 20px;
            position: relative;
            -webkit-backdrop-filter: saturate(180%) blur(20px);
            backdrop-filter: saturate(180%) blur(20px);
        }

        &:hover &__list,
        &:focus-within &__list {
            visibility: visible;
            opacity: 1;
        }

        &__list {
            &__wrap {
                margin-right: 4.4rem;
            }

            visibility: hidden;
            opacity: 0;
            transition: visibility 0s,
            opacity 0.2s linear;
            position: absolute;
            left: 64px;
            right: 0;
            top: 40px;
            width: 100%;
            margin-top: .4rem;
            display: flex;
            justify-content: center;

            @media only screen and (max-width: 760px) {
                top: 100px;
            }

            // @media only screen and (min-width: 1400px) {
            //     padding-top: 13.2rem;
            // }

            a {
                // border-bottom: 1px solid #ddd;
                color: #ebf4e5 !important;
                opacity: .8;
                display: block;
                padding: 10px 0;
                font-weight: 400;
                white-space: nowrap;

                &:hover {
                    color: #c6fda6;
                    opacity: 1;
                }
            }


            &__item {
                h5 {
                    display: block;
                    padding: 10px 0;
                    color: #c6fda6;
                    padding-right: 6px;
                    font-size: 17px;
                    flex-shrink: 0;
                    max-width: 100%;
                    font-family: var(--font-primary);
                    font-weight: 400;
                }

                ul {
                    list-style: none;
                    padding: 0;
                    margin-top: .4rem;
                }

            }



        }
    }
}



/***
a {
    text-decoration: none;
}

.container {
    margin: auto;
    width: 1000px;
}

ul {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
}


nav>ul>li {
    display: inline-block;
    font-size: 14px;
    padding: 0 15px;
    position: relative;
}

nav>ul>li>a {
    color: #fff;
    display: block;
    padding: 20px 0;
    // border-bottom: 3px solid transparent;
    transition: all .3s ease;
}

nav>ul>li:hover>a {
    color: #444;
    // border-bottom: 3px solid #444;
}

.mega-menu {
    background: #eee;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    position: absolute;
    left: 0;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    padding: 30px;

    // grid-area: bilic-landing__callout__left;
    background-color: var(--theme-black);
    background-image: radial-gradient(#212531 1px, var(--theme-black) 1px);
    // background-size: 20px 20px;
    // display: flex;
    // padding: 7.2rem;
    // position: relative;

}

.mega-menu h3 {
    color: #444;
}

.mega-menu .container {
    display: flex;
}

.mega-menu .item {
    flex-grow: 1;
    margin: 0 10px;
}

.mega-menu .item img {
    width: 100%;
}

.mega-menu a {
    // border-bottom: 1px solid #ddd;
    color: #4ea3d8;
    display: block;
    padding: 10px 0;
}

.mega-menu a:hover {
    // color: #2d6a91;
    color: var(--theme-primary);

}


.dropdown {
    position: static;
}

.dropdown:hover .mega-menu {
    visibility: visible;
    opacity: 1;
}


// Menu two
$grid-gutter-width: 30px;
$line-height-base: 1.428571429;
$gray: #555;
$link-hover-color: var(--theme-primary);



.nav>.dropdown-megamenu {
    position: static;
}

@media (max-width: 767px) {
    .navbar-nav .open .dropdown-container {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        border: 0;
        box-shadow: none;
        border-radius: 0;
    }
}

.dropdown-megamenu>.dropdown-container {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-width: 100%;
    padding: 0.5 * $grid-gutter-width;
}

.dropdown-megamenu .dropdown-menu {
    display: block;
}

.link-image .media-object {
    float: left;
    margin-bottom: 0.25 * $grid-gutter-width;
}

.link-image-sm+.link-image-sm .media-object {
    margin-left: 0.25 * $grid-gutter-width;
}

.thumbnail .caption {
    min-height: 120px;
}

.thumbnail:hover {
    text-decoration: none;
}


.list-links {
    list-style: none;
    padding: 0;

    li {
        line-height: 1.2 * $line-height-base;
    }

    a {
        color: $gray;

        &:hover,
        &:focus,
        &:active {
            color: $link-hover-color;
        }
    }

    .sub-header {
        font-weight: 500;
        font-size: 20px;
        color: #342243;
    }
}

.main-header {
    border-bottom: 1px solid #ddd;
    display: block;
    padding: 10px 0;

    color: #857a8e;
    padding-right: 6px;
    font-size: 18px;
    flex-shrink: 0;
    max-width: 100%;
}
***/