.bilic {
  &-pricing {
    background-color: var(--theme-black);

    .bilic-article-template {
      &__content {
        background-color: var(--theme-black);

        // .btn {
        //   background: #c7c7c714;
        //   color: rgba(var(--white-rgb), 0.8);
        //   border: none;
        // }
      }

      &__hero {
        display: none;
      }
    }

    &__hero {
      padding-block: 16rem;

      @media screen and (max-width: 760px) {
        padding-block-end: 4rem;
      }

      &-copy {
        color: #fff;

        h1 {
          --_fs: 5.6rem;
          max-width: 600px;
          font-size: var(--_fs);
          line-height: calc(var(--_fs) * 1.1);
          background: linear-gradient(180deg, #fff 22.5%, rgba(255, 255, 255, 0.7) 100%);
          background-clip: text;
          color: transparent;
          -webkit-text-fill-color: transparent;
        }

        p {
          --font-size: 1.72rem;
          font-size: var(--font-size);
          line-height: calc(var(--_fs) * 1.32);
          max-width: 590px;
          color: rgba(var(--white-rgb), 0.7);
        }

      }

      &-buttons {
        .btn {
          background: #c7c7c714;
          color: rgba(var(--white-rgb), 0.8);
          border: none;

          &:hover {
            color: #ecffe4;
            background: #48d1112e;
          }
        }
      }

      &-pills {
        max-width: 720px;
        margin-block-start: 1.6rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 1.2rem;
        column-gap: 0.4rem;
        text-align: center;

        p {
          background: #0c0c0d;
          color: #4d4d4d;
          margin: 0;
          display: flex;
          padding-inline: 2rem;
          padding-block: 0.6rem;
          border-radius: 5000px;
          font-size: 1.4rem;
          // font-weight: 500;
        }
      }
    }

    &__plans {
      padding: 2.4rem 0;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-rows: repeat(4, auto) 1fr auto;
      gap: 1.2rem 2.4rem;
      color: #fff;

      @media screen and (max-width: 760px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 24px;
      }
    }

    &__card {
      position: relative;
      display: grid;
      grid-template-rows: subgrid;
      grid-row: span 6;
      border-radius: 2.4rem;
      padding: 3.6rem;
      color: var(--white);
      border: 1px dashed #212321;
      font-size: 1.4rem;
      font-family: var(--font-primary);
      background-color: #060907;
      isolation: isolate;

      &::before {
        position: absolute;
        inset: 0;
        z-index: -20;
        border-radius: 2rem;
        opacity: 1;
        mix-blend-mode: overlay;
        content: "";
        background-image: url(./images/noise.webp);
      }

      &:nth-child(2) {
        transform: scale(1.05);
      }

      h2,
      p {
        color: var(--white);
        margin: 0;
      }

      h2 {
        --_fs: 2.8rem;
        font-size: var(--_fs);
        line-height: calc(var(--_fs) * 1.1);
        background: linear-gradient(180deg, #fff 22.5%, rgba(255, 255, 255, 0.7) 100%);
        background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
      }


      ol {
        list-style-type: none;
        padding: 0;
        margin: 0;
        margin-block-start: 1.2rem;
      }

      li {
        margin-bottom: 1.4rem;
        display: flex;
        align-items: start;
        gap: 0.8rem;
        line-height: 1.5;
        opacity: 0.7;
      }

      .monthly-price {
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 1.2rem;
        color: rgba(var(--white-rgb), 0.6);

        span {
          display: flex;
          align-items: center;
          font-size: 4rem;
          font-weight: 500;
          margin-bottom: -3px;
          color: #fff;
        }
      }

      &-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        --_fs: 1.4rem;
        font-size: var(--_fs);
        line-height: calc(var(--_fs) * 1.1);
        font-weight: 500;
        color: rgba(var(--white-rgb), 0.8);
        padding: 1.2rem 2.8rem;
        text-decoration: none;
        background: #c7c7c714;
        border: 1px solid #c7c7c714;
        border-radius: 32px;
        box-shadow: none;

        &:hover {
          color: #ecffe4;
          background: #48d1112e;
        }

        &--main {
          background: linear-gradient(to bottom, #2c9a19, #2c9a19);
          border: 1px solid #68ba384a;
          border-radius: 32px;
          box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.302), 0 1px 1px rgba(0, 0, 0, 0.18), inset 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px rgba(0, 0, 0, 0.2);
          cursor: pointer;
          -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);

          &:hover {
            color: #ecffe4;
            background: #47d111b3;
          }
        }
      }


      &-standard {
        position: absolute;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 32px;
        inset-block-start: -18px;
        inset-inline-start: 28%;
        z-index: 4;
        border-radius: 24px;
        padding: 0.2rem 2.0rem;

        &:not(:empty) {
          background: #0d150c;
          border: 1px solid #0d150c;
          border-radius: 24px;
          color: #2e6a25;
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
          inset-block-start: -16px;
          inset-inline-start: 30%;
          z-index: 4;
        }
      }

      &-divider {
        border-block: 0.4px solid #212321;
        padding-block: 1.2rem 3.2rem;
        margin-block-start: 1.2rem;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }

    &__features {
      margin-block: 16rem;

      header {
        span {
          --_fs: 1.4rem;
          display: flex;
          font-size: var(--_fs);
          line-height: calc(var(--_fs)* 1.48);
          color: var(--theme-primary);
          text-decoration: none;
          text-transform: uppercase;
          font-weight: 600;
          margin-block-end: 0.4rem;
        }

        h4 {
          --_font-size: 4.8rem;
          font-size: var(--_font-size);
          line-height: calc(var(--_font-size)* 1.1);
          font-family: var(--font-heading);
          letter-spacing: -.025em;
          margin-bottom: 1.8rem;
          max-width: 600px;
          background-image: linear-gradient(0deg, dimgray 0%, white 100%);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          -webkit-text-fill-color: transparent;
        }

        p {
          max-width: 556px;
          --_fs: 1.6rem;
          font-size: var(--_fs);
          line-height: calc(var(--_fs)* 1.6);
          color: rgba(var(--white-rgb), 0.7);
          margin-block-start: 0.4rem;
          margin-block-end: 4.8rem;
        }
      }

      &-card {
        background-color: #060907;
        border: 1px solid rgba(255, 255, 255, 0.08);
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px;
        position: relative;
        overflow: hidden;
        height: 100%;
        isolation: isolate;
        margin-block-end: 1.2rem;

        &::before,
        &::after {
          content: '';
          position: absolute;
          z-index: 930;
          width: 0.6px;
          height: 90px;
          background-image: linear-gradient(177deg, rgba(118, 110, 245, 0), #68ba38 35%, #2b9a1a 65%, rgba(45, 169, 245, 0));
          opacity: .95;
          top: 33%;
        }

        &::before {
          left: 0%;
        }

        &::after {
          right: 0%;
        }

        h6 {
          --_fs: 2.4rem;
          font-size: var(--_fs);
          background: radial-gradient(circle farthest-side, #5cc23a00, #90af84),
            radial-gradient(circle at 140%, #92cc7b 23%, #fff);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          -webkit-text-fill-color: transparent;
          margin-block: 1.2rem 0.2rem;
        }

        span {
          --_fs: 1.2rem;
          font-size: var(--_fs);
          line-height: calc(var(--_fs)* 1.48);
          color: rgba(var(--white-rgb), 0.4);
          margin-block-end: 0.8rem;
          font-weight: 600;
          text-transform: uppercase;
        }

        p {
          --_fs: 1.6rem;
          font-size: var(--_fs);
          line-height: calc(var(--_fs)* 1.48);
          color: rgba(var(--white-rgb), 0.7);
          margin-block: 0.4rem 2rem;
        }

        a {
          --_fs: 1.2rem;
          font-size: var(--_fs);
          line-height: calc(var(--_fs)* 1.48);
          color: rgba(var(--white-rgb), 0.7);
          text-decoration: none;
          text-transform: uppercase;
          font-weight: 600;
          padding-block-end: 0.8rem;
          margin-top: auto;

          &:hover {
            color: #ecffe4;
          }
        }
      }
    }

  }

  &-faq {
    &__header {
      padding-block: 2rem 1.6rem;

      h4 {
        --_font-size: 3.6rem;
        font-size: var(--_font-size);
        line-height: calc(var(--_font-size)* 1.1);
        font-family: var(--font-heading);
        letter-spacing: -.025em;
        margin-bottom: 0.8rem;
        max-width: 432px;
        background-image: linear-gradient(0deg, dimgray 0%, white 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;

        @media screen and (max-width: 760px) {
          --font-size: 3.2rem;
        }
      }

      p {
        max-width: 556px;
        --_fs: 1.6rem;
        font-size: var(--_fs);
        line-height: calc(var(--_fs)* 1.6);
        color: rgba(var(--white-rgb), 0.7);
        margin-block-start: 0.4rem;
      }
    }

    &__widget {
      details {
        // --bg-color: var(--theme-primary);
        // background: var(--bg-color);
        margin-block: 2rem;

        summary {
          --font-size: 1.6rem;
          font-size: var(--font-size);
          line-height: calc(var(--font-size) * 1.5);
          font-family: var(--font-primary);
          margin: 0;
          font-weight: 400;
          color: var(--white);
          padding: 2rem;
          list-style-type: none;
          display: flex;
          gap: 20px;
          background: transparent;
          border-radius: 16px;
          border: 1px solid rgba(255, 255, 255, 0.08);
          // box-shadow: 0px 3px 6px -3px rgb(247, 237, 207),
          //   0px 2px 4px -2px rgb(247, 237, 207),
          //   0px 1px 2px -1px rgb(247, 237, 207),
          //   0px 1px 1px -1px rgb(247, 237, 207),
          //   0px 1px 0px -1px rgb(247, 237, 207);
          // align-items: center;

          &::-webkit-details-marker {
            display: none;
          }

          &::before {
            content: url(./images/faq-chevron.svg);
            will-change: transform;
            transition: all .3s ease-in-out;
            display: inline-flex;
            align-items: center;
          }
        }

        // &:first-child {
        //   summary {
        //     border-block-start: 0.6px solid #FFE099;
        //   }
        // }

        p,
        ul {
          background: transparent;
          padding: 3.2rem 6rem;
          --font-size: 1.7rem;
          font-size: var(--font-size);
          --color: rgba(var(--white-rgb), .7);
          color: var(--color);
          line-height: calc(var(--font-size) * 1.64);
          border-radius: 0 0 16px 16px;
          border: 1px solid rgba(255, 255, 255, 0.08);
        }

        ul {
          list-style: none;
        }

        &[open] {
          &>summary {
            --bg-color: transparent;
            background: var(--bg-color);
            border-block-end: none;
            border-radius: 16px 16px 0 0;


            &::before {
              transform: rotate(180deg);
              transform-origin: 50% 40% 0px;
            }
          }

          ul {
            margin: 0;
          }
        }
      }
    }
  }

  &-cta {
    &__copy {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #060907;
      border: 1px solid rgba(255, 255, 255, 0.08);
      border-radius: 24px;
      width: 100%;
      border-radius: 32px;
      margin-block-start: -72px;
      margin-block-end: 5rem;
      z-index: 100;
      position: relative;
      overflow: hidden;
      isolation: isolate;
      padding-block: 4.4rem;
      padding-inline: 4rem;

      h4 {
        --_font-size: 3.6rem;
        font-size: var(--_font-size);
        line-height: calc(var(--_font-size)* 1.1);
        font-family: var(--font-heading);
        letter-spacing: -.025em;
        margin-bottom: 1.2rem;
        max-width: 323px;
        background-image: linear-gradient(0deg, dimgray 0%, white 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
      }

      p {
        max-width: 536px;
        --_fs: 1.6rem;
        font-size: var(--_fs);
        line-height: calc(var(--_fs)* 1.6);
        color: rgba(var(--white-rgb), 0.7);
        margin-block-start: 0.4rem;
        margin-block-end: 2.4rem;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        background: #c7c7c714;
        color: rgba(var(--white-rgb), 0.8);
        border: none;
        padding: 0.8rem 2.4rem;
        white-space: nowrap;
        max-width: 200px;
        min-height: 48px;
        font-size: 14px;
        text-decoration: none;

        &:hover {
          color: #ecffe4;
          background: #47d111b3;
        }
      }
    }
  }

}