/* #tsparticles {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
} */

.custom-form-container {
  /* width: 50%; */
  /* max-width: 20vw; */
}

.custom-form {
  padding: 50px;
}