.bilic-article-template {
  &-agent {

    .bilic-global-header__inner {
      .container-xl {
        @media only screen and (min-width: 1400px) {
          max-width: 1320px;
        }
      }

      z-index: 1000;
      top: 64px;
      background: transparent;

      .bilic-global-header__navigation {
        display: flex;
        justify-content: center;

        nav {
          -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
          background: #fff;
          // background-image: linear-gradient(to right, #ffffffcc 2%, #e1ffe7a3 15%, #e8ffdab3 70%, #ffffff 96%);
          // box-shadow: 0 4px 5px 2px #daf8e12b, 0 -1px 6px 4px #daf8e12b;
          height: 58px;
          width: 100%;
          border-radius: 80px;
          padding-inline: 2rem 1.6rem;
        }

        &__logo {
          color: var(--bs-body-color);
          font-size: 20px;
        }

        &__link {
          border: 1px solid transparent;



          a,
          button {
            color: var(--bs-body-color);
          }

        }

        &__btn {
          .btn {
            color: #212529;
            height: 42px;
            font-size: 13px;
            font-weight: 500;
            font-family: var(--font-primary);
            background: transparent;
            border: 1px solid #212529;
            border-radius: 32px;
            padding-inline: 20px;
          }

        }

        &__socials {
          ul.nav {
            li.nav-item {
              color: var(--bs-body-color);

              a.nav-link {
                height: 28px;
                width: 28px;
                border-color: var(--bs-body-color);

                i {
                  color: var(--bs-body-color);
                  font-size: 1.2rem;
                }
              }
            }
          }
        }

      }

      .calendly-badge-widget .calendly-badge-content {
        color: var(--theme-black) !important;
        background: #fff;
        border: none;
        border-radius: 32px;
        box-shadow: none;
        font-family: var(--font-primary);
        margin-block-start: 1.8rem !important;
      }
    }

    .bilic-article-template__hero {
      .container-xl {
        &:first-child {
          @media only screen and (min-width: 1400px) {
            max-width: 1180px;
          }
        }
      }

      background: linear-gradient(90deg, #edfff2 0%, #edfff2 100%);
      // background: linear-gradient(90deg, #d8fce2 0%, #edfff2 60%, #edfff2 100%);
      padding-block: 18.2rem 2rem;
      justify-content: center;
      isolation: isolate;
      max-height: unquote("max(110vh, 820px)");

      @media only screen and (max-width: 760px) {
        background: linear-gradient(90deg, #edfff2 0%, #edfff2 100%);
        padding-block: 17rem 2rem;
      }

      @media only screen and (min-width: 1400px) {
        max-height: unquote("max(110vh, 820px)");
        padding-block: 20.2rem 2rem;
      }

      &__background {
        position: absolute;
        width: 100%;
        opacity: 0;
        background-position: 50%;
        background-size: 1090px;
        inset: 0;
        overflow: hidden;
        pointer-events: none;

        &--neo {
          width: 60%;
          inset: auto 25% -250px auto;
        }

        &--alt {
          min-width: 100vw;
          min-height: 100vh;
          position: absolute;
          inset: 0;
          background-repeat: repeat;
          background-size: contain;
          background-position: top left;
          z-index: 10;
        }

        &--grid {
          position: absolute;
          inset-block-start: 18.9%;
          inset-inline-start: 30%;
          background-repeat: repeat;
          background-size: contain;
          background-position: top left;
          z-index: -1;

          @media screen and (max-width: 760px) {
            inset-block-start: 14.9%;
            inset-inline-start: -20%;
          }
        }
      }

      &__text {
        justify-content: center;
        align-items: center;

        &::before {
          content: '';
          /* inset-block-start: 86px; */
          width: 500px;
          height: 500px;
          display: flex;
          background-image: linear-gradient(179deg, #dafae2, transparent 16%);
          position: absolute;
          border-radius: 50%;
          z-index: -1;
        }

        .line-border {
          --border-width: 1px;
          --border-color: #7b9350;
          height: 100%;
          width: 92%;
          position: absolute;
          display: flex;
          justify-content: center;
          z-index: 50000;

          @media screen and (max-width: 760px) {
            width: 88%;
          }

          &-one,
          &-three {
            border-block-start: var(--border-width) dashed var(--border-color);
            height: 1px;

            @media screen and (max-width: 760px) {
              inset-inline-start: 6px;
            }
          }

          &-one {
            inset-block-start: 4px;

            @media screen and (max-width: 760px) {
              inset-block-start: 0;
            }
          }

          &-three {
            inset-block-start: 78px;

            @media screen and (max-width: 760px) {
              inset-block-start: 39px;
            }
          }

          &-two,
          &-four {
            width: 86%;
            height: 78px;
            inset-block-start: 0px;
          }

          &-two {
            border-inline-start: var(--border-width) dashed var(--border-color);

            @media screen and (max-width: 760px) {
              inset-inline-start: 3px;
            }
          }

          &-four {
            border-inline-end: var(--border-width) dashed var(--border-color);

            @media screen and (max-width: 760px) {
              inset-inline-end: 38px;
            }
          }

        }

        small {
          font-family: 'Nanum Pen Script', cursive;
          letter-spacing: .3px;
          font-size: 20px;
          font-weight: 500;
          padding: 0;
          margin: 0;
          margin-block-end: 0.1rem;
          margin-inline-start: 1.6rem;
          background: none;
          border: none;
          font-style: normal;
          background: linear-gradient(270deg, #59843a, #22291d, #59843a);
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          animation: textAminate 8s linear infinite;
          background-size: 800% 800%;
          // -webkit-backdrop-filter: blur(8px);
          // backdrop-filter: blur(8px);
          // background-color: #ffffffb0;
          // background-image: linear-gradient(123deg, #caffe31a, #bcfeef1a);
          // box-shadow: inset 0 -1px 0 0 #ffffff12;
          // border-radius: 99px;
          // border: 1px solid #b0dcbc;
          // font-size: 14px;
          // font-weight: 500;
          // margin-block-end: 2rem;
          // padding-block: 0.4rem;
          // padding-inline: 1.2rem;
          // text-decoration: none;
          // width: auto;
          // font-family: var(--font-primary);

          @media screen and (max-width: 760px) {
            font-size: 16px;
            display: none;
          }
        }

        h1 {
          --_fs: 7.2rem;
          font-size: var(--_fs);
          line-height: calc(var(--_fs) * 1.2);
          letter-spacing: -.025em;
          padding-block: 1.2rem 1.2rem;
          color: var(--white);
          overflow: hidden;
          text-align: left;
          min-height: 190px;
          white-space: nowrap;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          width: 100%;

          @media screen and (max-width: 760px) {
            --_fs: 4.4rem;
            font-size: var(--_fs);
            line-height: calc(var(--_fs) * 1.25);
            // text-align: center;
            justify-content: flex-start;
            align-items: center;
            min-height: 40px;
            max-width: 100%;
            white-space: inherit;
            margin-block-end: 0.4rem;
          }

          // @media screen and (max-width: 950px) {
          //   min-height: 239px;
          //   white-space: initial;
          // }

          @media only screen and (min-width: 1400px) {
            --_fs: 6.8rem;
          }

          // &:nth-child(2) {
          //   padding-block: 1.2rem;
          //   height: 84px;

          //   @media screen and (max-width: 760px) {
          //     height: 40px;
          //   }
          // }

          &>span {
            position: absolute;
            z-index: 200;

            @media screen and (max-width: 760px) {
              position: relative;
              max-width: 340px;
            }

            span {
              background: linear-gradient(178deg, #212529, #212529 25.17%, #212529 50%, #212529 73.09%, #212529);
              -webkit-background-clip: text;
              background-clip: text;
              color: transparent;
              -webkit-text-fill-color: transparent;

              span {
                position: relative;
                font-family: var(--font-heading-grotesk-italic);

                &::after {
                  content: url();
                  color: #2b2c34;
                  background: linear-gradient(to right, #cbffa500 2%, #c5ffd1c2 15%, #f1ff6447 70%, #f3fff463 96%);
                  letter-spacing: -.075rem;
                  padding-right: 1rem;
                  border-radius: 6.5rem;
                  position: absolute;
                  z-index: -1;
                  width: 142%;
                  left: -42px;
                  right: -20px;
                  top: 7px;
                  bottom: 0;
                  height: 78%;
                }
              }

              .word {
                background: linear-gradient(178deg, #212529, #212529 25.17%, #212529 50%, #212529 73.09%, #212529);
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
                -webkit-text-fill-color: transparent;
              }
            }

            /*----------------------- //create beautiful shadow effect ---------------------
            cite {
              font-style: normal;
              -webkit-background-clip: initial;
              background-clip: initial;
              color: var(--theme-black);
              position: relative;
              -webkit-text-fill-color: var(--theme-black);

              &:after {
                content: 'team with AI agents';
                position: absolute;
                inset: -6px 0 0 0px;
                z-index: -1;
                font-family: var(--font-heading);
                -webkit-text-stroke-width: 11px;
                -webkit-text-stroke-color: transparent;
                color: transparent;

              }
            } */
          }




        }

        p {
          --_fs: 2rem;
          font-size: var(--_fs);
          text-align: left;
          line-height: calc(var(--_fs)*1.5);
          color: rgba(var(--theme-black-rgb), 0.9);
          margin-block-start: 2px;
          font-weight: 400;
          max-width: 656px;
          // letter-spacing: -0.02rem;

          @media screen and (max-width: 760px) {
            --_fs: 1.74rem;
            margin: 0;
            padding: 0;
            margin-block-start: 0;
            line-height: calc(var(--_fs)*1.5);
            min-width: auto;
            max-width: 345px;

          }

          div {
            // @media screen and (max-width: 760px) {
            //   display: inline-block;
            //   text-align: unset;
            //   width: 100%;
            // }

            &:not(:last-child) {
              @media screen and (max-width: 760px) {
                white-space: nowrap;
              }
            }
          }


        }
      }

      &__image {
        // transition: all 1.2s ease-in;
        // position: absolute;
        transition: all 1.2s ease-in;
        inset-inline: -1%;
        inset-block-start: -1%;
        position: relative;

        @media screen and (max-width: 760px) {
          min-height: 250px;
        }

        img {
          position: absolute;
          z-index: 50;
          margin-block-start: 3.2rem;
          background: rgba(255, 255, 255, 0.01);
          border: 1px solid #c6edcb8c;
          border-radius: 24px;
          box-shadow: inset 0 0 0 8px #e2fce5;
          padding: 12px;

          @media only screen and (min-width: 1400px) {
            margin-block-start: 8.2rem;
          }

          @media screen and (max-width: 760px) {
            margin-block-start: 1.2rem;
          }
        }

        &::before {
          background: linear-gradient(180deg, #edfef200 0%, #edfef2 70.48%, #eefef2 82.36%);
          content: "";
          display: block;
          height: 83%;
          left: 9px;
          right: 0px;
          position: absolute;
          top: 134px;
          width: 98.3%;
          z-index: 60;
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
        }

        // &::after {
        //   background: linear-gradient(180deg, #05050700 0%, #050507db 70.48%, #050507f5 82.36%);
        //   content: "";
        //   display: block;
        //   height: 100%;
        //   left: 0;
        //   position: absolute;
        //   top: 0;
        //   width: 100%;
        //   z-index: 60;
        // }
      }

      &__video {
        position: absolute;
        z-index: 500;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        inset-inline-start: 440px;
        inset-block-start: 147px;
        border: 2px solid var(--theme-primary);

        @media only screen and (max-width: 760px) {
          inset-inline-start: 425px;
          inset-block-start: 168px;
        }

        @media only screen and (min-width: 1400px) {
          inset-inline-start: 502px;
          inset-block-start: 232px;
        }

        video {
          object-position: -86px -91px;
          object-fit: contain;
          aspect-ratio: 16/9;
          position: absolute;
          z-index: 1000;
          width: 238px;
          height: 611px;

        }
      }

      &__links {
        @media screen and (max-width: 760px) {
          // margin-inline: 9.4rem;
          width: 100%;
          margin-block: 3.2rem 4rem;
          display: flex;
          flex-direction: column;
          row-gap: 16px;
        }

        isolation: isolate;

        &__btn {
          --_fs: 1.6rem;
          color: var(--theme-black);
          background: linear-gradient(to bottom, var(--theme-primary), var(--theme-primary));
          border: 1px solid var(--theme-primary);
          border-radius: 32px;
          // box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.302), 0 1px 1px rgba(0, 0, 0, 0.18), inset 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px rgba(0, 0, 0, 0.2);
          cursor: pointer;
          font-size: var(--_fs);
          font-weight: 500;
          font-family: var(--font-primary);
          line-height: 1.6rem;
          min-height: 52px;
          padding: 1.2rem 2.8rem;
          -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
          position: relative;
          z-index: 100;

          &::after {
            content: url();
            position: absolute;
            inset: -5px -5px;
            z-index: -1;
            background: transparent;
            border: 4px solid #c0f59f;
            border-radius: 32px;
          }


          &:hover {
            color: var(--theme-black);
          }

          &:active {
            color: var(--theme-black) !important;
            border-color: #c0f59f !important;
          }

          &-alt {
            // background: transparent;
            // color: var(--theme-black);
            // border-color: 1px solid var(--theme-black);
            // box-shadow: none;
            color: var(--theme-black);
            background: linear-gradient(to bottom, #ffffff, #f3f4f4);
            border: 1px solid #fcfcfc;
            border-radius: 32px;
            box-shadow: inset 0 1px #f7fff9, inset 0 -1px #e2e2e4;

            &::after {
              content: url();
              position: absolute;
              inset: -5px -5px;
              z-index: -1;
              background: transparent;
              border: 4px solid #e3f8e9;
              border-radius: 32px;
            }

            &:hover {
              color: var(--theme-black);
              border-color: var(--theme-black);
              opacity: .8;
            }

            &:focus {
              box-shadow: inset 0 1px #f7fff9, inset 0 -1px #e2e2e4;
            }


          }
        }
      }
    }

    .bilic-article-template__curve {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      z-index: 20;
      width: 100%;
      margin: 0 auto;
      z-index: 5000;
      display: block;
    }

    .bilic-article-template__content {
      background: var(--white);
      padding-block-start: 0;
      padding-block-end: 0;

      .btn {
        &:hover {
          background: darken($color: #68ba38, $amount: 5%);
        }
      }

      // @media screen and (max-width: 760px) {
      //   padding-block-start: 0.4rem;
      // }

      &__usecase {
        padding-block: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 1rem;

        @media screen and (max-width: 760px) {
          padding-bottom: 4rem;
        }

        h5 {
          --_fs: 52px;
          font-size: var(--_fs);
          line-height: calc(var(--_fs)*1.2);
          letter-spacing: -1.12px;
          margin-block: 0.4rem 1.4rem;
          z-index: 300;
          font-weight: 300;
          max-width: 700px;

          span {
            span {
              background: linear-gradient(90deg, #44962d, #5cc23a 25.17%, #208b4a 50%, #1c703d 73.09%, #208b4a);
              -webkit-background-clip: text;
              background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }

        p {
          --_fs: 1.68rem;
          font-size: var(--_fs);
          line-height: calc(var(--_fs)*1.5);
          margin: 0.4rem 0 2rem;
          max-width: 700px;
        }


      }

      &__solutions {
        background: var(--white);
        margin-block: 6rem 0;
        position: relative;




        &__matrix {
          opacity: .4;

          @media screen and (max-width: 760px) {
            display: none;
          }

          &>* {
            position: absolute;
          }

          &-one {
            width: 9px;
            left: 108px;
            top: -186px;

          }

          &-two {
            width: 10px;
            left: 48px;
            top: -131px;

          }

          &-three {
            width: 10px;
            left: 76px;
            top: -231px;
          }
        }

        header {
          h4 {
            max-width: 470px;
          }
        }

        &__list {
          gap: 2rem;
          isolation: isolate;

          &__item {
            background: #05080300;
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 20px;
            padding-block: 2.4rem;
            padding-inline: 2.4rem;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto 1fr;
            gap: 1.2rem;
            color: #fff;
            position: relative;

            &::before,
            &::after {
              content: '';
              position: absolute;
              z-index: 930;
              width: 0.6px;
              height: 90px;
              background-image: linear-gradient(177deg, rgba(118, 110, 245, 0), #68ba38 35%, #2b9a1a 65%, rgba(45, 169, 245, 0));
              opacity: .95;
              top: 33%;
            }

            &::before {
              left: 0%;
            }

            &::after {
              right: 0%;
            }

            figure {
              margin: 0;

              img {
                border: none;
                width: 14%;
                margin-block: 0.4rem;
              }
            }

            &__card {
              position: relative;
              z-index: 200;

              h3 {
                --_fs: 2.2rem;
                font-size: var(--_fs);
                background: linear-gradient(180deg, #ffffffe0 22.5%, rgba(255, 255, 255, 0.7) 100%);
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
                -webkit-text-fill-color: transparent;
              }

              p {
                --_fs: 1.5rem;
                font-size: var(--_fs);
                line-height: calc(var(--_fs) * 1.48);
                color: rgba(var(--white-rgb), 0.6);
                margin-block-start: 0.8rem;
              }
            }
          }
        }

        &-wrap {
          @media only screen and (min-width: 1400px) {
            padding-block: 2rem;
            padding-inline: 4rem;
          }
        }
      }

      &__tagline {
        min-height: 95vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-block-start: 14rem;
        position: relative;
        background: url(../images/tag-bg.webp);
        background-repeat: no-repeat;
        background-size: 60%;
        background-position-y: 120px;
        background-position-x: 930px;


        @media screen and (max-width: 760px) {
          padding-block-start: 8rem;
          padding-inline: 1.2rem;
          width: 100%;
          overflow: hidden;
        }




        &::after {
          content: '';
          display: inline-flex;
          width: 100%;
          height: 1px;
          background-image: radial-gradient(circle at 10%, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.19) 50%, rgba(255, 255, 255, 0));
          inset-block-end: -44px;
          position: absolute;
        }


        header {
          padding-block-end: 4.8rem;
          // flex-direction: column;
          align-items: flex-start;

          h2 {
            --_font-size: 4.8rem;
            font-size: var(--_font-size);
            line-height: calc(var(--_font-size)*1.2);
            font-family: var(--font-primary);
            letter-spacing: -.025em;
            margin-bottom: 1.8rem;
            // max-width: 240px;
            background-image: linear-gradient(0deg, dimgray 0%, white 100%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            -webkit-text-fill-color: transparent;
            text-align: left;

          }

          h5 {
            --_ff: var(--font-heading);
            --_fs: 5.6rem;
            font-size: var(--_fs);
            line-height: calc(var(--_fs) * 1.2);
            font-family: var(--_ff);
            font-weight: 600;
            position: relative;
            max-width: initial;
            text-align: left;

            &>div {
              background: linear-gradient(to right, #d2d2d2 50%, #585858 50%);
              -webkit-background-clip: text;
              background-clip: text;
              color: transparent;
              -webkit-text-fill-color: transparent;
              background-size: 200% 100%;
              background-position-x: 100%;
            }

            span {
              // background: linear-gradient(90deg, #44962d, #5cc23a 25.17%, #4fd918 50%, #4fd918 73.09%, #688b20);
              // -webkit-background-clip: text;
              // background-clip: text;
              // -webkit-text-fill-color: transparent;
            }

            @media screen and (max-width: 760px) {
              --_fs: 5rem;
            }
          }

          p {
            --_fs: 1.92rem;
            font-size: var(--_fs);
            max-width: 534px;
            line-height: calc(var(--_fs) * 1.6);
            color: rgba(var(--white-rgb), 0.7);
          }
        }
      }

      &__howto {
        padding-block: 12rem 20.8rem;

        @media screen and (max-width: 760px) {
          padding-block-end: 0.8rem;
        }

        &__header {
          transform: translateY(-100px);
          opacity: 0;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-block-end: 4rem;

          @media screen and (max-width: 760px) {
            align-items: flex-start;
            text-align: left;
            margin-block-end: 1.2rem;
          }


          h2 {
            background: linear-gradient(90deg, #44962d, #5cc23a 25.17%, #4fd918 50%, #4fd918 73.09%, #688b20);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-block-end: 1.2rem;
          }

          h4 {
            --_font-size: 4.8rem;
            font-size: var(--_font-size);
            line-height: calc(var(--_font-size)*1.2);
            font-family: var(--font-heading);
            letter-spacing: -.025em;
            margin-bottom: 1.8rem;
            max-width: 640px;
            background-image: linear-gradient(0deg, dimgray 0%, white 100%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            -webkit-text-fill-color: transparent;
          }

          p {
            max-width: 630px;
            --_fs: 1.6rem;
            font-size: var(--_fs);
            line-height: calc(var(--_fs) * 1.6);
            color: rgba(var(--white-rgb), 0.7);
            margin-block-start: 0.4rem;
            margin-block-end: 4.8rem;

            @media screen and (max-width: 760px) {
              margin-block-end: 3.6rem;
            }
          }
        }

        &__steps {
          // background-color: #0c0c13;
          // background-color: #070b08;
          background-color: #060907;
          border: 1px solid rgba(255, 255, 255, 0.08);
          border-radius: 24px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 32px;
          position: relative;
          overflow: hidden;
          min-height: 540px;
          height: 100%;
          isolation: isolate;

          &::before {
            position: absolute;
            inset: 0;
            z-index: -20;
            opacity: 1;
            mix-blend-mode: overlay;
            content: "";
            background-image: url(../images/noise.webp);
          }

          h6 {
            --_fs: 2.8rem;
            font-size: var(--_fs);
            background: linear-gradient(180deg, #fff 22.5%, rgba(255, 255, 255, 0.7) 100%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            -webkit-text-fill-color: transparent;
            margin-block: 1.2rem 0.6rem;

          }

          p {
            --_fs: 1.6rem;
            font-size: var(--_fs);
            line-height: calc(var(--_fs) * 1.48);
            color: rgba(var(--white-rgb), 0.7);
            margin-block-start: 0.4rem;
          }

          figure {
            display: flex;
            width: 100%;
            justify-content: center;

            img {
              width: 88%;
              position: absolute;
            }

          }


          &--one {
            figure {
              img {
                width: 76%;
                bottom: -36px;

                @media screen and (max-width: 760px) {
                  width: 92%;
                  bottom: -15px;
                }
              }
            }
          }

          &--two {
            figure {
              img {
                width: 60%;
                bottom: -180px;

                @media screen and (max-width: 760px) {
                  width: 88%;
                  bottom: -145px;
                }
              }
            }
          }

          &--three {
            figure {
              img {
                width: 64%;
                bottom: -120px;

                @media screen and (max-width: 760px) {
                  width: 90%;
                  bottom: -64px;
                }
              }
            }
          }

          &--four {
            figure {
              img {
                width: 58%;
                bottom: -66px;

                @media screen and (max-width: 760px) {
                  width: 90%;
                  bottom: -86px;
                }
              }
            }
          }



        }

      }

      &__wordings {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto;
        grid-auto-rows: 1fr;
        overflow: hidden;
        height: 100%;
        width: 100%;
        background: #f1fff5;

        P {
          display: flex;
          justify-content: center;
          background: #f1fff5;
          padding-inline: 40px;
          padding-block: 16px;
          border-radius: 40px;
          font-size: 16px;
          white-space: nowrap;
          font-family: var(--font-heading-grotesk-italic);
        }
      }

      &__teaser {
        padding-block: 8rem;
        position: relative;

        @media screen and (max-width: 760px) {
          padding: 3.6rem 0 !important;
          padding-block-start: 0.4rem;
        }

        header {
          padding-block: 12rem 8rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          z-index: 30;
          text-align: center;

          @media screen and (max-width: 760px) {
            align-items: flex-start;
            text-align: left;
            padding-inline: 2rem;
            padding-block: 4rem 4rem;
          }

          &::after {
            content: '';
            display: inline-flex;
            width: 100%;
            height: 1px;
            background-image: radial-gradient(circle at 10%, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.19) 50%, rgba(255, 255, 255, 0));
            inset-block-start: 6px;
            position: absolute;
          }

          h4 {
            --_ff: var(--font-heading);
            --_fs: 4.8rem;
            font-size: var(--_fs);
            line-height: calc(var(--_fs) * 1.3);
            font-family: var(--_ff);
            font-weight: 600;
            background-image: linear-gradient(0deg, dimgray 0%, white 100%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            -webkit-text-fill-color: transparent;
            max-width: 440px;
            position: relative;
          }

          p {
            --_fs: 1.68rem;
            font-size: var(--_fs);
            line-height: calc(var(--_fs)*1.5);
            margin: 0.4rem 0 0.5rem;
            max-width: 560px;
          }

        }

        &__illustration {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 39%;
            position: absolute;
            top: 154px;
            right: 104px;

            @media screen and (max-width: 760px) {
              position: static;
              max-width: 100%;
              margin-top: 2rem;
            }

            @media only screen and (min-width: 1400px) {
              max-width: 36%;
              position: absolute;
              top: -5px;
              display: flex;
              right: 68px;
            }

          }
        }

        &__text {

          h3 {
            --width: 500px;
            --_fs: 4rem;
            font-size: var(--_fs);
            line-height: calc(var(--_fs)*1.2);
            color: var(--white);
            background: linear-gradient(180deg, #fff 22.5%, rgba(255, 255, 255, 0.7) 100%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            -webkit-text-fill-color: transparent;
            margin-bottom: 1.6rem;
            max-width: var(--width);

            span {
              background: linear-gradient(90deg, #44962d, #5cc23a 25.17%, #4fd918 50%, #4fd918 73.09%, #688b20);
              -webkit-background-clip: text;
              background-clip: text;
              color: transparent;
              -webkit-text-fill-color: transparent;
            }
          }

          p {
            --clr-white: #ffffff94;
            color: var(--clr-white);
            --width: 500px;
            --_fs: 1.8rem;
            font-size: var(--_fs);
            line-height: calc(var(--_fs)*1.5);
            max-width: 480px;
          }

        }

        &-wrap {
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;
          background-color: #060907;
          /* background: linear-gradient(252deg,#ffffff,#50d828 60.44%); */
          background-image: linear-gradient(#4fed5e14, #4fed5e03);
          border-radius: 42px;
          padding: 48px;
          overflow: hidden;
          min-height: 500px;
          height: 100%;
          isolation: isolate;
          position: relative;

          @media screen and (max-width: 950px) {
            display: flex;
            flex-direction: column;
            gap: 44px;
          }

          &::before {
            position: absolute;
            inset: 0;
            z-index: -20;
            opacity: 0.2;
            mix-blend-mode: overlay;
            content: "";
            background-image: url(../images/noise.webp);
          }

          &::after {
            content: '';
            margin: 4px;
            height: 100%;
            overflow: hidden;
            background-image: url(../images/dots.svg);
            background-repeat: repeat;
            background-size: 30px 30px;
            border-radius: 16px;
            -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 1), transparent);
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-type: alpha;
            position: absolute;
            inset: 0;
            z-index: -1;
          }

          @media screen and (max-width: 760px) {
            grid-template-columns: 1fr;
            border-radius: 24px;
            padding-inline: 2.8rem !important;
          }

        }

        &-righttext {
          background: linear-gradient(to bottom, #faf9f3, transparent 80%);
          position: relative;
          isolation: isolate;

          .content-grid {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: repeat(12, 188px);
            grid-template-rows: repeat(15, 47px);
            background: transparent;
            overflow: hidden;
            z-index: -1;
            // display: none;

            &__first {
              grid-area: 1/1/-1/2;
              border-inline: 1px dashed #d0d5dd;
            }

            &__second {
              grid-area: 1/5/-1/6;

              border-inline-end: 1px dashed #d0d5dd;
            }

            &__three {
              grid-area: 3/1/1/-1;
              border-block: 1px dashed #d0d5dd;
              margin-block-start: -12px;
            }

            &__four {
              grid-area: 9/2/5/-1;
              border-block-end: 1px dashed #d0d5dd;
            }

            &__five {}
          }

          .bilic-article-template__content__tab {
            margin-block: 2.4rem 6rem;

            .nav-tabs {
              border-bottom: none;
              background: #f7f8fa;
              border-radius: 12px;
              padding-block: 0.6rem;
              padding-inline: 0.6rem;
              width: 100%;
              width: fit-content;
            }

            .nav-tabs .nav-link {
              border: none;
              color: #040802;
              font-size: 1.2rem;
              font-weight: 400;
              padding-block: 1rem;
              padding-inline: 1.6rem;
              border-radius: 8px;
              font-weight: 500;

              &:hover {
                background: #ffffffa1;
              }
            }

            #tabs .nav-tabs .nav-link {
              border: none;
              border-top-left-radius: 0.25rem;
              border-top-right-radius: 0.25rem;
              color: #68ba38;
              font-size: 15px;
            }

            #tabs .nav-tabs .nav-item.show .nav-link,
            .nav-tabs .nav-link.active {
              color: #68ba38;
              background-color: #fff;
              border-bottom: none;
              font-weight: 500;
            }


            .nav-tabs:not(.nav-fill):not(.nav-justified) .nav-link,
            .nav-pills:not(.nav-fill):not(.nav-justified) .nav-link {
              width: fit-content;
            }

          }

          .agent {
            &-list {
              margin-block: 5.6rem 6.4rem;

              @media screen and (max-width: 760px) {
                margin-block-start: 1.2rem;
              }

              h6 {
                --_fs: 2rem;
                font-size: var(--_fs);
                line-height: calc(var(--_fs)*1.2);
                letter-spacing: -1.12px;
                z-index: 300;
                font-weight: 500;
                font-family: var(--font-primary);
                margin-inline-start: 1.4rem;
                margin-block: 2rem;
                display: flex;
                flex-direction: column;
                gap: 4px;


                span {
                  background: linear-gradient(90deg, #288a0d, #59b63a 25.17%, #208b4a 50%, #1c703d 73.09%, #208b4a);
                  -webkit-background-clip: text;
                  background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
              }

              &__body {
                display: grid;
                grid-template-columns: 1fr;
                grid-auto-rows: 1fr;
                grid-auto-flow: column;
                gap: 12px;
                margin-inline: 1.2rem 0;
                margin-block: 1.2rem 3.2rem;
                overflow: scroll;

                @media screen and (max-width: 760px) {
                  grid-template-columns: 1fr;

                }
              }
            }

            &-select {
              background-image: linear-gradient(356deg, #ffe2ab33 1%, #ffffff);
              border-radius: 12px;
              min-width: 380px;
              min-height: 200px;

              @media screen and (max-width: 760px) {
                background-image: linear-gradient(90deg, #abffc233 0%, #effef3);
                min-height: auto;
                min-width: 328px;
              }


              .card {
                background: transparent;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;
                border: none;
                gap: 12px;
                width: 100%;
                border: none;
                gap: 12px;

                &-image {
                  display: flex;
                  justify-content: flex-end;
                  width: 100%;

                  img {
                    --_size: 84px;
                    border: 0;
                    border-radius: 50%;
                    display: block;
                    margin: 0px;
                    position: relative;
                    height: var(--_size);
                    width: var(--_size);
                    z-index: 0;
                    border: 4px solid #b5e0c0;
                  }
                }

                &-body {
                  display: flex;
                  align-items: flex-start;
                  flex-direction: column;
                  justify-content: space-between;
                  margin: 0;
                  padding: 0;
                  gap: 4.8rem;
                  min-height: 100%;

                  // @media screen and (max-width: 760px) {
                  //   flex-direction: row;
                  // }

                }

              }

              &__info {
                display: flex;
                flex-direction: column;
                align-self: center;

                h5 {
                  margin: 0;
                  --_fs: 2rem;
                  font-size: var(--_fs);
                  line-height: calc(var(--_fs)*1.2);
                  font-family: var(--font-primary);
                  font-weight: 500;
                  margin-block: 0.2rem 1.4rem;
                }

                ul {
                  font-family: var(--font-primary);
                  --_fs: 1.6rem;
                  font-size: var(--_fs);
                  line-height: calc(var(--_fs)*1.5);
                  margin: 0.4rem 0 0.5rem;
                  color: rgba(var(--theme-black-rgb), 0.8);
                  list-style: none;
                  display: flex;
                  flex-direction: column;
                  padding: 0;
                  gap: 16px;

                  li {
                    position: relative;
                    padding-inline-start: 2.4rem;

                    &::before {
                      content: url('../images/star.svg');
                      position: absolute;
                      left: -1px;
                      top: 4px;
                    }
                  }
                }
              }

              &__radio {

                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                padding-inline: 2.4rem;
                padding-block: 2.0rem 0;
                border: 2px solid transparent;
                position: relative;
                border-radius: 12px;
                min-height: 100%;

                &::before {
                  content: url();
                  position: absolute;
                  inset: 0;
                }

                &:hover {
                  border-color: var(--theme-primary);
                }

                input {
                  display: none;

                  // &+.card-body:after {
                  //   content: "";
                  //   position: absolute;
                  //   height: 16px;
                  //   width: 16px;
                  //   background: transparent;
                  //   right: 20px;
                  //   top: 20px;
                  //   border-radius: 100%;
                  //   border: 3px solid transparent;
                  //   box-shadow: 0px 0px 0px 2px var(--theme-primary);
                  // }

                  &:checked+.card-body {
                    // border: 2px solid var(--theme-primary);


                    &::after {
                      content: "";
                      position: absolute;
                      height: 16px;
                      width: 16px;
                      background: var(--theme-primary);
                      right: 20px;
                      top: 20px;
                      border-radius: 100%;
                      border: 3px solid #ffffff;
                      box-shadow: 0px 0px 0px 3px var(--theme-primary);
                    }
                  }
                }

              }

            }

            &-form {
              display: flex;
              width: 100%;
              justify-content: center;
              padding-block: 6.4rem;
              margin-block: 2rem 8.4rem;
              background: #ffffff;
              border-radius: 34px;
              will-change: transform;
              transition: all 0.5s ease-in;


              form {
                width: 100%;
                width: unquote("max(400px, 40%)");
                will-change: transform;
                transition: all 0.3s ease-in;

                label {
                  font-family: var(--font-primary);
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 24px;
                }

                .form-control {
                  height: 48px;
                  border-radius: 6px;
                  border: 0.8px solid #42424438;
                  padding-inline-start: 1.2rem;

                  &::placeholder {
                    color: rgba(var(--theme-black-rgb), 0.6);
                    font-size: 1.2rem;
                  }

                  &:hover {
                    box-shadow: inset 0 0 0 2px var(--theme-primary-rgb);
                  }

                  &:focus,
                  &:focus-visible {
                    outline: transparent solid 1px;
                    box-shadow: inset 0 0 0 2px rgba(var(--theme-primary-rgb), 1),
                      0 0 0 2px rgba(var(--theme-primary-rgb), .44);
                    border: none;
                  }
                }

                .btn {
                  white-space: nowrap;
                  width: 100%;
                  color: #fff;
                  --_fs: 16px;
                  font-size: var(--_fs);
                  line-height: calc(var(--_fs)*1.2);
                  font-family: var(--font-primary);
                  font-weight: 500;

                  &:hover {
                    background: darken($color: #68ba38, $amount: 5%);
                  }

                }
              }
            }

          }

          .bilic-article-template__content__teaser {
            &__illustration {
              display: flex;
              flex-direction: column;
              align-items: center;

              img {
                position: absolute;
                top: 10%;
                display: flex;
                left: 156px;
                z-index: -1;
              }


              audio::-webkit-media-controls {
                &-panel {
                  background-color: #79b36eba;
                }

                // &-mute-button {
                //   background-color: #4c862b;
                //   border-radius: 50%;
                // }

                &-play-button {
                  background-color: #4c862b;
                  border-radius: 50%;

                  &:hover {
                    background-color: #396421;
                  }
                }


              }
            }
          }


        }

        &-lefttext {
          background: transparent;

          .bilic-article-template__content__usecase {
            padding-block: 4rem 6rem;

            @media screen and (max-width: 760px) {
              padding-block-end: 3rem;
            }

            h5 {
              max-width: 640px;
            }

            p {
              max-width: 700px;
            }
          }

          .bilic-article-template__content__teaser {
            padding-block: 6rem 8rem;

            &-wrap {
              // background: transparent;

              // &::after {
              //   -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), transparent);
              //   display: none;
              // }
            }

            // &__text {
            //   h3 {
            //     background: var(--theme-black);
            //     -webkit-background-clip: text;
            //     background-clip: text;
            //     color: transparent;
            //     -webkit-text-fill-color: transparent;
            //     margin-bottom: 1.2rem;
            //   }

            //   p {
            //     --_fs: 1.68rem;
            //     font-size: var(--_fs);
            //     color: var(--theme-black);
            //     line-height: calc(var(--_fs)*1.5);
            //     margin: 0.4rem 0 0.5rem;
            //     max-width: 560px;
            //   }
            // }

            &__illustration {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              width: 100%;

              @media screen and (max-width: 950px) {
                width: 100%;
              }
            }
          }

          img {
            right: initial;
            max-width: 39%;
            left: 110px;

            @media screen and (max-width: 760px) {
              position: static;
              max-width: 100%;
              margin-bottom: 2rem;
            }

            @media only screen and (min-width: 1400px) {
              right: initial;
              left: 92px;
              max-width: 34%;
              top: -1px;
            }

          }

        }

      }



    }

    .bilic-landing__banner__manifest__advisors {
      background: #faf9f4;

      @media only screen and (min-width: 1400px) {
        padding: 0;
        padding-block-start: 1.2rem;
      }

      @media screen and (max-width: 760px) {
        padding-inline: 2rem;
      }

      h4 {
        font-size: 1.6rem;
        font-family: var(--font-primary);
        margin-bottom: 2.4rem;
        font-weight: 400;
        color: #4c4c4c;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 760px) {
          align-items: flex-start;
        }
      }

      &__slide {
        gap: 32px;
        width: 100%;

        @media screen and (max-width: 760px) {
          justify-content: flex-start;
        }

        img {
          width: 10%;
          opacity: .7;
          filter: invert(1);

          @media screen and (max-width: 760px) {
            width: 24%;
          }
        }
      }
    }

    .bilic-landing__number {
      background-image: radial-gradient(#62ff920f 1px, #050508 1px);
      background-size: 20px 20px;
      padding-block: 4.4rem;
      padding-inline: 3.6rem;

      &-wrap {
        background-image: none;
        background-size: initial;
        margin-block: 6rem 12rem;
      }

      &__item {

        h4 {
          font-family: var(--font-heading-grotesk-italic);
          color: #c8c8d8;
          font-size: 2.4rem;
          display: flex;
          align-items: center;
          background-image: linear-gradient(0deg, dimgray 0%, #ffffffb5 100%);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          -webkit-text-fill-color: transparent;

          span {
            font-size: 60px;
            font-family: var(--font-heading-grotesk);
            margin-right: 4px;
            align-self: flex-end;
          }
        }

        p {
          max-width: 266px;
          --_fs: 1.56rem;
          font-size: var(--_fs);
          line-height: calc(var(--_fs)*1.5);
          color: rgba(var(--white-rgb), 0.5);
        }
      }

    }

    .bilic-article-template__bento {
      .bento {
        margin-block: 1.6rem 3.6rem;

        &__grid {
          display: grid;
          grid-template-columns: 1fr 1.4fr 0.3fr 2fr;
          // grid-template-rows: auto auto auto auto;
          grid-auto-rows: 1fr;
          grid-column-gap: 30px;
          grid-row-gap: 30px;
          grid-auto-flow: row;
          grid-template-areas:
            "bento__item-bento__item-1 bento__item-bento__item-1 bento__item-bento__item-1 bento__item-bento__item-2"
            "bento__item-bento__item-1 bento__item-bento__item-1 bento__item-bento__item-1 bento__item-bento__item-2"
            "bento__item-bento__item-3 bento__item-bento__item-3 bento__item-bento__item-4 bento__item-bento__item-4"
            "bento__item-bento__item-3 bento__item-bento__item-3 bento__item-bento__item-4 bento__item-bento__item-4";


          @media screen and (max-width: 760px) {
            display: flex;
            flex-direction: column;
          }

        }

        &__item {
          min-height: 540px;
          background: #fcfffd;
          box-shadow: 0 0 0 0.5px #e4f2ec, 0 0 0 1px #d9f0e6, 0 0 0 3.5px #f6fffb, 0 0 0 4px #d9f0e6;
          isolation: isolate;
          position: relative;
          border-radius: 32px;
          padding-inline: 3.2rem;
          padding-block: 2.8rem;
          overflow: hidden;

          @media screen and (max-width: 760px) {
            min-height: 580px;
          }

          &::after {
            content: url();
            position: absolute;
            inset: 0;
            position: absolute;
            inset: 0;
            background-size: 16px 16px;
            background-image: radial-gradient(circle, #ddeee1 1px, rgba(0, 0, 0, 0) 1px);
            border-radius: 32px;
            z-index: -1;
          }

          h6 {
            --_fs: 2.4rem;
            --_heading-width: 200px;
            font-size: var(--_fs);
            line-height: calc(var(--_fs)*1.2);
            max-width: var(--_heading-width);
            color: var(--bs-body-color);
            margin-block-end: 1.2rem;

            &::before {
              content: url();
              width: 50px;
              height: 50px;
              background: #edfff1;
              border-radius: 50%;
              inset-block: 1.3rem;
              inset-inline: 2rem;
              display: block;
              position: absolute;
              z-index: -1;
            }
          }

          p {
            --_fs: 1.6rem;
            --_heading-width: 550px;
            font-size: var(--_fs);
            max-width: var(--_heading-width);
            line-height: calc(var(--_fs)*1.5);
            color: rgba(var(--theme-black-rgb), 0.8);
            margin-block-start: 0.4rem;
            font-weight: 400;
          }

          ul {
            list-style: none;
            padding: 0;
            margin-block: 2rem;
            display: flex;
            flex-direction: column;
            gap: 1.2rem;

            li {
              --_fs: 1.6rem;
              font-family: var(--font-primary);
              font-size: var(--_fs);
              line-height: calc(var(--_fs)*1.5);
              font-weight: 500;
              position: relative;
              padding-inline-start: 2.4rem;

              &::before {
                content: url('../images/star-list.svg');
                position: absolute;
                left: -1px;
                top: 4px;
              }
            }
          }

          figure {
            img {
              position: absolute;
            }
          }

          &-1 {
            grid-area: bento__item-bento__item-1;

            img {
              inset-block-end: -12%;
              inset-inline-end: -16%;
            }
          }

          &-2 {
            grid-area: bento__item-bento__item-2;

            img {
              inset-inline: 13%;
              inset-block-end: -68%;

              @media screen and (max-width: 760px) {
                inset-inline: 4%;
                inset-block-end: -54%;
              }
            }
          }

          &-3 {
            grid-area: bento__item-bento__item-3;

            img {
              inset-inline: 19%;
              inset-block-end: -26%;

              @media screen and (max-width: 760px) {
                inset-inline: 3%;
                inset-block-end: -30%;
              }
            }
          }

          &-4 {
            grid-area: bento__item-bento__item-4;

            img {
              inset-inline: 13%;
              inset-block-end: -20%;

              @media screen and (max-width: 760px) {
                inset-inline: -7%;
                inset-block-end: -17%;
              }
            }
          }


        }


      }
    }
  }
}


.c-glow {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 1;
  opacity: 1;
  pointer-events: none;

  @media screen and (max-width: 760px) {
    overflow: hidden;
  }


}

.c-glow__wrap {
  width: 100%;
  position: relative;
  height: 100%;
  filter: blur(150px);

  // &--one {
  //   filter: blur(175px);
  // }

  // &--two {
  //   filter: blur(175px);
  // }
}

.c-glow--one {
  position: absolute;
  border-radius: 362.53px;
  transform: rotate(279deg);
  mix-blend-mode: overlay;
  top: -32%;
  left: 37%;
  width: 400px;
  height: 400px;
  aspect-ratio: 683/492;
  background: linear-gradient(267.56deg, #27f515 22.11%, #03e40e 40.73%, #03e40e 54.78%, #03e40e 69.88%, #03e40e 89.56%);
}


.c-glow--two {
  position: absolute;
  border-radius: 362.53px;
  transform: rotate(279deg);
  mix-blend-mode: overlay;
  top: -32%;
  left: 37%;
  width: 400px;
  height: 400px;
  aspect-ratio: 683/492;
  background: linear-gradient(267.56deg, #27f515 22.11%, #03e40e 40.73%, #03e40e 54.78%, #03e40e 69.88%, #03e40e 89.56%);
}


.bilic__glow {
  position: absolute;
  z-index: 930;
  width: 17%;
  height: 0.2px;
  background-image: linear-gradient(90deg, rgba(118, 110, 245, 0), #68ba38 35%, #c6d55c 65%, rgba(45, 169, 245, 0));
  opacity: .95;
  left: 41%;
  top: -25px;
}

.modal.bilic-article-template__content__modal {
  top: 120px;

  .modal-dialog {
    position: relative;
    // overflow: hidden;

    &.modal-lg,
    &.modal-xl {
      @media (min-width: 992px) {
        --bs-modal-width: 640px !important;
      }
    }
  }
}

.modal {

  &-backdrop {
    background-color: #a0151514;

    &.show {
      opacity: 1;
    }
  }
}




// html.lenis {
//   height: auto;
// }

// .lenis.lenis-smooth {
//   scroll-behavior: auto;
// }

// .lenis.lenis-smooth [data-lenis-prevent] {
//   overscroll-behavior: contain;
// }

// .lenis.lenis-stopped {
//   overflow: hidden;
// }

// .lenis.lenis-scrolling iframe {
//   pointer-events: none;
// }



// .calendly-badge-widget {
//   z-index: 6000;

//   .calendly-badge-content {
//     background: transparent !important;
//     color: #000000 !important;
//     border: 1px solid #fff;
//   }
// }


// .bilic-article-template__hero{
//   display: none !important;
// }


// react orbit

.orbit-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  // width: 100%;
  padding: 0 20px;
  position: absolute;
  margin-top: -220px;
}


.atom-nucleus {
  background-color: #38b6ff !important;
  background-image: url('https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSSp3nAELu306FoslMKh3aHwaEc2UdNg9NlSL23nY9NCnFKjbHQ') !important;
  /* Replace with the path to your image */
  background-size: cover !important;
  background-position: center !important;
  border-radius: 50%;
  left: 50%;
  position: absolute;
  top: 50%;
}

.electron {
  background-color: white;
  border-radius: 50px;
  left: 50%;
  position: absolute;
  top: 0%;
}

.planet-cyan {
  background-color: cyan !important;
}

.nice-shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.atom-orbit {
  border-radius: 50%;
  position: absolute;
}

@keyframes spin-right {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin-left {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes textAminate {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  to {
    background-position: 0 50%;
  }
}