.bilic-article-template {
  &-idw {

    .bilic-global-header__inner {
      .bilic-global-header__navigation {
        &__link {

          a,
          button {
            color: var(--white);
          }
        }
      }
    }

    .bilic-article-template__hero {
      background: var(--theme-black);
      justify-content: center;

      @media only screen and (min-width: 1400px) {
        min-height: 86vh;
      }

      &__background {
        position: absolute;
        width: 84%;
        top: 30px;
        opacity: .2;
      }

      &__text {
        text-align: center;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 760px) {
          text-align: left;
          justify-content: flex-start;
          align-items: flex-start;
        }

        h5 {
          font-size: 9.2rem;
          line-height: 100px;
          color: var(--white);

          @media screen and (max-width: 760px) {
            font-size: 5.2rem;
            line-height: 64px;
          }
        }

        p {
          font-size: 2.0rem;
          max-width: 700px;
          line-height: 3.5rem;
          color: rgba(var(--white-rgb), .9);

          @media screen and (max-width: 760px) {
            font-size: 20px;
          }

        }
      }

      &__links {
        &__btn {
          background: linear-gradient(to right, #44962d, #208b4a);
          color: var(--white);

          &-alt {
            background: transparent;
            border: 1px solid var(--white);
          }
        }
      }
    }

    .bilic-article-template__content {
      &__tagline {
        header {


          // h4 {}

          p {
            font-size: 1.8rem;
            max-width: 580px;
            line-height: 30px;
          }
        }
      }

      &__solutions {
        header {
          h4 {
            max-width: 560px;
          }
        }
      }

      &__teaser {
        &__illustration {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 39%;
            position: absolute;
            top: 154px;
            right: 104px;

            @media screen and (max-width: 760px) {
              position: static;
              max-width: 100%;
              margin-top: 2rem;
            }

            @media only screen and (min-width: 1400px) {
              max-width: 37%;
              position: absolute;
              top: 115px;
              display: flex;
              right: 228px;
            }

          }
        }

        &-righttext {

          img {
            right: initial;
            max-width: 39%;
            left: 110px;

            @media screen and (max-width: 760px) {
              position: static;
              max-width: 100%;
              margin-bottom: 2rem;
            }

            @media only screen and (min-width: 1400px) {
              right: initial;
              left: 190px;
              max-width: 34%;
            }

          }

        }
      }
    }
  }
}