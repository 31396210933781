.bilic-landing {
  position: relative;

  &__banner {
    position: relative;
    overflow: hidden;
    min-height: 90vh;
    width: 100%;
    background-color: var(--theme-primary);
    // background-image: url(./images/print-left.svg),
    //   url(./images/print-right.svg);
    background-position: -5% 100%,
      101% 100%;
    background-repeat: no-repeat;
    background-size: 26%,
      37%;
    overflow: hidden;
    // padding-bottom: 2rem;

    @media screen and (max-width: 760px) {
      background: var(--theme-primary);
      align-items: center;
      justify-content: center;
      display: flex;
      min-height: 60vh;
    }

    &__manifest {
      padding: 19rem 0 5.6rem;
      // z-index: 5000;
      // position: relative;

      @media screen and (max-width: 760px) {
        padding: 16rem 0 5rem;
      }

      @media only screen and (min-width: 1400px) {
        padding: 22rem 0 5.6rem;
      }

      .container {
        @media only screen and (min-width: 1400px) {
          max-width: 1300px;
        }
      }

      &__text {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        @media screen and (max-width: 760px) {
          margin-left: 1rem;
        }

        &::after {
          content: url(./images/banner-pattern-alt.svg);
          display: block;
          position: absolute;
          top: -30px;
          left: 41%;
          z-index: 1;

          @media screen and (max-width: 760px) {
            top: -13px;
            left: 16%;
          }

          @media only screen and (min-width: 1400px) {
            top: -70px;
          }
        }

        // &::after {
        //   content: url(./images/swup-alt.svg);
        //   display: block;
        //   position: absolute;
        //   top: 62px;
        //   left: -2px;
        // }

        h2 {
          font-size: 104px;
          line-height: 116px;
          position: relative;
          font-family: var(--font-heading-grotesk);
          color: var(--theme-black);
          letter-spacing: -.02em;
          position: relative;
          z-index: 50;

          @media screen and (max-width: 760px) {
            font-size: 54px;
            line-height: 62px;
          }

          span {
            font-family: var(--font-heading-grotesk-italic);
            letter-spacing: -0.09rem;
            background: #050802;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: var(--theme-primary);
            font-size: 7.6rem;
            width: 90px;
            height: 90px;
            border-radius: 50%;

            @media screen and (max-width: 760px) {
              width: 50px;
              height: 50px;
              font-size: 3.2rem;
            }
          }

        }

        p {
          max-width: 531px;
          line-height: 30px;
          font-size: 2rem;
          opacity: .98;
          position: relative;
          margin: 1.8rem 0 1.2rem;
          font-family: var(--font-primary);
          position: relative;
          z-index: 50;
          text-align: center;

          @media screen and (max-width: 760px) {
            margin: 0.2rem 0 1.2rem;
            --font-size: 17px;
            font-size: var(--font-size);
            line-height: calc(var(--font-size) * 1.5);

          }
        }

      }


      &__buttons {
        gap: 12px;
        align-items: center;
        padding-top: 2rem;

        @media screen and (max-width: 760px) {
          align-items: flex-start;
          width: 100%;
        }

        a.btn {
          background: var(--theme-black);
          color: var(--theme-primary);
          font-size: 1.6rem;
          position: relative;
          z-index: 52;
          height: 48px;
          border-radius: 28px;
          font-family: var(--font-primary);
          font-weight: 500;
          align-items: center;
          gap: 8px;
          padding: 8px 16px;
          padding-right: 8px;
          // padding-inline: 16px 4px;

          i {
            background: transparent;
            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-left: .4rem;
            border: 1px solid var(--theme-primary);
            font-size: 1.2rem;
          }

          &.landing-secondary {
            background: #ffffff;
            border: 1px solid #ffffff;
            color: var(--theme-black);
            pointer-events: none;
            filter: opacity(0.5);
            cursor: crosshair;
          }
        }

        .btn-social {
          background: #0b2454;
        }
      }

      &__advisors {
        padding-top: 7.2rem;

        @media screen and (max-width: 760px) {
          padding-top: 4.8rem;
        }

        @media only screen and (min-width: 1400px) {
          padding-top: 13.2rem;
        }


        h4 {
          font-family: var(--font-primary);
          margin-bottom: 2.4rem;
          font-weight: 400;

          @media screen and (max-width: 760px) {
            margin-bottom: 2.4rem;
          }
        }

        &__slide {
          display: flex;
          align-items: center;
          justify-content: center;

          @media screen and (max-width: 760px) {
            flex-wrap: wrap;
            gap: 20px 0;
            justify-content: flex-start;
            // margin-left: -23px;
          }

          img {
            width: 12%;

            @media screen and (max-width: 760px) {
              width: 33%;
            }
          }
        }
      }
    }

    &-svg {
      position: absolute;
      bottom: -85px;
      left: 100px;

      @media screen and (max-width: 760px) {
        display: none;
      }

      @media only screen and (min-width: 1400px) {
        bottom: -105px;
        left: 14.5%;
      }

    }
  }


  &__callout {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    // grid-template-columns: 1.2fr 1.2fr 0.9fr 0.9fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas: "bilic-landing__callout__left bilic-landing__callout__left bilic-landing__callout__right-1 bilic-landing__callout__right-2"
      "bilic-landing__callout__left bilic-landing__callout__left bilic-landing__callout__right-3 bilic-landing__callout__right-4";
    width: 100%;
    height: 100%;
    min-height: 800px;



    &__left {
      grid-area: bilic-landing__callout__left;
      background-color: var(--theme-black);
      background-image: radial-gradient(#212531 1px, var(--theme-black) 1px);
      background-size: 20px 20px;
      display: flex;
      padding: 0 7.2rem;
      position: relative;

      @media screen and (max-width: 760px) {
        padding: 0 2.4rem;
      }
    }


    &__right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 4.4rem 3.2rem 2.4rem;

      &-1 {
        grid-area: bilic-landing__callout__right-1;
        background: #c6ffa6;
      }

      &-2 {
        grid-area: bilic-landing__callout__right-2;
        background: #d8ffc2;
      }

      &-3 {
        grid-area: bilic-landing__callout__right-3;
        background: #d8ffc2;

        @media screen and (max-width: 760px) {
          background: #c6ffa6;
        }
      }

      &-4 {
        grid-area: bilic-landing__callout__right-4;
        background: #fff;
        position: relative;

        img {
          position: absolute;
          right: 124px;
          bottom: 36px;
        }

        @media screen and (max-width: 760px) {
          display: none;
        }
      }

      h2 {
        font-size: 16px;
        font-family: var(--font-heading-grotesk);
        line-height: 24px;
        margin-bottom: 1.2rem;
      }

      p {
        line-height: 24px;
        font-size: 1.4rem;
        margin: 0.8rem 0 0.4rem;
        font-family: var(--font-primary);
      }

    }


    &__text {
      margin-top: 9.6rem;

      @media screen and (max-width: 760px) {
        margin-top: 3.6rem;
      }

      h2 {
        font-size: 52px;
        font-family: var(--font-heading-grotesk);
        line-height: 64px;
        margin-top: 0.4rem;
        color: #fff;

        @media screen and (max-width: 760px) {
          font-size: 20px;
          line-height: 35px;
          padding: 0 0.8rem;
        }



        span {
          font-family: var(--font-heading-grotesk-italic);
          letter-spacing: -0.09rem;
          color: var(--theme-primary)
        }

        @media only screen and (min-width: 1400px) {
          max-width: 1060px;
        }
      }

      p {
        line-height: 36px;
        font-size: 2.4rem;
        margin: 3.2rem 0 3.6rem;
        font-family: var(--font-primary);
        color: rgba(var(--white-rgb), 0.8);

        i {
          font-style: normal;
          background: linear-gradient(135deg, #d7fdc3 32.5%, #bbf798 45%, #c6fda6 60%, #d7fdc3 70%);
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        @media screen and (max-width: 760px) {
          font-size: 1.6rem;
          padding: 0 0.8rem;
          line-height: 28px;
        }
      }
    }

    &__illustration {
      img {
        position: absolute;
        bottom: 72px;
        right: 60px;
      }

      @media screen and (max-width: 760px) {
        display: none;
      }

    }

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }

    /* Portrait and Landscape */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      display: flex;
      flex-direction: column;

    }

  }

  &__number {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    padding: 8.4rem 0 6.8rem;
    gap: 2rem;

    @media screen and (max-width: 760px) {
      grid-template-columns: 1fr;
      padding: 4rem 3rem 3rem;
    }

    /* Portrait and Landscape */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      grid-template-columns: repeat(auto-fit, minmax(40%, 47%));
      grid-template-rows: 2fr;
      place-content: center;
      place-items: center;
    }

    @media only screen and (min-width: 1400px) {
      gap: 5rem;

    }

    &__item {
      h4 {
        font-family: var(--font-heading-grotesk-italic);
        color: var(--theme-black);
        font-size: 2.4rem;
        display: flex;
        align-items: center;

        &::before {
          content: "*";
          display: inline-flex;
          color: var(--theme-primary);
          font-size: 28px;
          margin-right: 4px;
        }

        span {
          color: var(--theme-black);
          font-size: 60px;
          font-family: var(--font-heading-grotesk);
          margin-right: 4px;
          align-self: flex-end;

          // background: linear-gradient(90deg, #dbfb92, #d6fe7c 25.17%, #dbf59e 50%, #dbfb92 73.09%, #dbfb92);
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;


        }

      }

      p {
        color: var(--theme-black);
        max-width: 266px;
        line-height: 28px;

      }
    }

    &-wrap {
      background-image: radial-gradient(#21253126 1px, #fff 1px);
      background-size: 20px 20px;

    }
  }

  &__media {
    padding: 18rem 0 14rem;
    background: #fff;

    @media screen and (max-width: 760px) {
      padding: 5rem 0 4rem;
    }

    &__heading {
      h3 {
        font-size: 80px;
        line-height: 87px;
        font-family: var(--font-heading-grotesk);
        color: var(--theme-black);
        letter-spacing: -0.09rem;

        span {
          -webkit-font-smoothing: antialiased;
          -webkit-text-stroke: 1px var(--theme-black);
          -webkit-text-fill-color: transparent;
        }
      }
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr 1fr;
      gap: 44px;
      background-image: radial-gradient(#212531 1px, #fff 1px);
      background-size: 20px 20px;
      padding: 4rem;

      @media screen and (max-width: 768px) {
        overflow-x: scroll;
      }

      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        grid-template-columns: repeat(auto-fit, minmax(35%, 45%));
        grid-template-rows: 1fr 1fr 1fr;
      }


      &__item {
        a {
          text-decoration: none;
        }

        h6 {
          display: flex;
          background: #fff;
          font-family: var(--font-primary);
          font-weight: 700;
          font-size: 16px;
          justify-content: center;
          align-items: center;
          gap: 10px;

          i {
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
            border: 1px solid var(--theme-black);
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  &__article {
    padding: 4rem 0 16.8rem;
    background: #fff;

    @media screen and (max-width: 760px) {
      padding: 4rem 0 6.8rem;
    }

    &__item {
      a {
        h3 {
          font-family: var(--font-primary);
          font-weight: 700;
          font-size: 18px;
          line-height: 26px;
          margin: 12px 0 16px 8px;
        }

        h6 {
          margin: 16px 0 8px 8px;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 1.2rem;
          background-color: #d5f7c1;
          display: inline-block;
          padding: 6px 8px;
        }

        img {
          width: 100%;
          object-fit: contain;
        }

        p {
          max-width: 97%;

          span {
            margin-left: 12px;
            color: #68ba38;
          }
        }

        &:hover {
          color: rgba(var(--black-rgb), .9);

        }


      }
    }
  }

  &__cta {
    background: var(--theme-primary);
    padding: 8rem 0;
    background-image: url(./images/print-left-alt.svg), url(./images/print-right-alt.svg);
    background-size: 46%,
      46%;
    background-position: -18% 36%,
      120% 61%;
    background-repeat: no-repeat;

    @media only screen and (max-width: 575px) {
      padding: 3rem 0;
      background-position: -18% 104%,
        120% 93%;
    }

    &__header {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      h2 {
        font-size: 4.8rem;
        font-family: var(--font-heading-grotesk);
        color: var(--theme-black);
        margin-bottom: 1.6rem;
        line-height: 58px;
        letter-spacing: -0.0525rem;
        max-width: 620px;

        @media only screen and (max-width: 575px) {
          font-size: 2.4rem;
          line-height: 32px;
          max-width: 300px;
          margin-bottom: 2.6rem;
        }
      }

      p {
        font-size: 2rem;
        max-width: 470px;
        line-height: 34px;
        margin-bottom: 2rem;
      }

      a.btn {
        background: #fff;
        color: #0d1528;
        font-size: 2rem;
        min-width: 240px;
        padding: 0.8rem 1.6rem;
        margin-top: 1.2rem;

        i {
          margin-right: 1rem;
        }

      }
    }


  }

  &__modal {
    &.show {
      display: flex !important;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    // .modal-dialog {
    //   width: 50% !important;
    //   max-width: initial;
    //   height: 600px !important;
    //   position: relative;
    // }

    // .modal-content {
    //   height: 600px;
    // }

    &-btn {
      display: flex;
      font-size: 1.6rem;
      align-items: center;

      i {
        margin-right: 12px;
        font-size: 23px;
        color: var(--theme-black);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--theme-black);
        height: 40px;
        width: 40px;
        border-radius: 50%;
        padding-left: 5px;
      }

      span {
        display: block;
        text-align: left;
      }

      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
      }

    }

    .modal {
      &-dialog {
        width: 70vw;
        max-width: initial;
        height: 640px !important;
        position: relative;
      }

      &-content {
        background: transparent;
        border: none;
        height: 640px !important;
      }

      &-header {
        border: none;
        text-align: center;
        justify-content: center;

        h5 {
          font-family: "Gilroy-medium",
            sans-serif;
          font-size: 1.6rem;
          margin: 12px 0 8px;
          line-height: 40px;
        }
      }
    }
  }

}

.main-root-hX4vn {
  right: 84px !important;
  max-width: 360px !important;
  bottom: 24px !important;
}

.css-1ig9tfq {
  bottom: 8px !important;
}


// .background-glow {
//   z-index: 1;
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
// }

// .background-glow__wrap {
//   width: 100%;
//   // position: relative;
//   height: 100%;
//   filter: blur(240px);
// }

// .background-glow__one {
//   position: absolute;
//   left: -63px;
//   top: -166px;
//   width: 500px;
//   height: 500px;
//   /* margin-top: -250px; */
//   /* margin-left: -250px; */
//   border-radius: 100%;
//   background-color: #ecc25d1c;
// }