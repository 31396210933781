.bilic-article-template {
  &-ftm {
    .bilic-global-header__inner {
      .bilic-global-header__navigation {
        &__link {

          a,
          button {
            color: var(--white);
          }
        }
      }
    }

    .bilic-article-template__hero {
      &__background {
        &-ftm {
          object-fit: contain;
          width: 33%;
          position: absolute;
          right: 80px;
          top: 136px;

          @media screen and (max-width: 760px) {
            display: none;
          }

          @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            width: 44%;
            right: -86px;
            bottom: -117px;
            top: initial;
          }


          @media only screen and (min-width: 1400px) {
            width: 26%;
            right: 232px;
            top: 208px;
          }

        }
      }

      &__text {
        h5 {
          @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            max-width: 600px;
          }

          @media screen and (max-width: 760px) {
            font-size: 59px;
            line-height: 64px;
          }

        }
      }

      &__links {

        &__btn {
          border: 1px solid var(--theme-primary) !important;
          background: var(--theme-primary) !important;

          &-alt {
            background: transparent !important;
            color: var(--theme-black) !important;
            border: 1px solid var(--theme-black) !important
          }
        }


      }
    }

    .bilic-article-template__curve {
      display: none !important;
    }


  }
}



// .bilic-article-template {
//   &__header {

//     &__background {

//       &-right {
//         object-fit: contain;
//         width: 61%;
//         position: absolute;
//         right: -50px;
//         top: 18px;
//       }
//     }
//   }

//   &__content {

//     &__hero {
//       header {

//         h4 {}

//         p {}
//       }
//     }

//     &__features {

//       &__list {

//         &__item {

//           h6 {}
//         }
//       }
//     }

//     &__teaser {

//       &-wrap {}

//       &__text {
//         h3 {}

//         p {}

//       }
//     }

//     &__solutions {

//       &__header {
//         h4 {}
//       }

//       &__list {

//         &__item {

//           h3 {}

//           p {}
//         }
//       }
//     }

//     &__cta {

//       header {

//         h4 {}

//         p {}
//       }
//     }

//     &__cta-foot {

//       &__header {

//         h2 {}

//         a.btn {

//           i {}

//         }
//       }
//     }

//   }
// }