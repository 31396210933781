.c-announcement {
  position: absolute;
  height: 60px;
  max-height: 60px;
  width: 100%;
  background: url('./images/banner.webp'),
    #050802;
  z-index: 1000;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  // top: 0;
  left: 0;

  @media screen and (max-width: 760px) {
    height: 80px;
    background-position: 324px 1px;
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  a {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 1.4rem;
  }

  &__text {
    p {
      color: rgba(var(--white-rgb), .8);
      padding: 0;
      margin: 0;

      @media screen and (max-width: 760px) {
        text-align: center;
      }

      span {
        color: var(--theme-primary);
      }
    }
  }

  // &__text {
  //   flex: 0 1 60%;

  // }

  &__logo {
    display: flex;
    align-items: center;
    gap: 14px;
    position: relative;
    // flex: 0 1 30%;

    // img {
    //   width: 36%;
    // }

    span {
      white-space: nowrap;
      display: flex;
      align-items: center;
      gap: 12px;
      color: rgba(var(--white-rgb), .8);
      font-size: 1.2rem;
    }

  }


  &--agent {
    background: linear-gradient(to right, #d0fdd8 2%, #c5ffd1c2 15%, #f1ff6447 70%, #f0fed3 96%);

    .c-announcement__text {
      p {
        color: var(--theme-black);
        padding: 0;
        margin: 0;

        @media screen and (max-width: 760px) {
          text-align: center;
        }

        span {
          color: var(--theme-black);
        }
      }
    }

    .c-announcement__logo {
      span {
        color: var(--theme-black);
      }

    }
  }
}