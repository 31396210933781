.bilic-article-template {
  &-training {

    .bilic-global-header__inner {
      top: 60px;
      backdrop-filter: blur(8px);
      background-color: #0505081f;
      // box-shadow: inset 0 -1px 0 0 #ffffff12;

      &::after {
        content: '';
        display: inline-flex;
        width: 100%;
        height: 1px;
        background-image: radial-gradient(circle at 10%, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.04) 50%, rgba(255, 255, 255, 0));
        inset-block-start: 72px;
        position: absolute;

      }



      .bilic-global-header__navigation {
        &__link {
          border: 1px solid transparent;

          a,
          button {
            color: var(--white);
          }
        }

        &__btn {
          .btn {
            border: 1px solid rgba(var(--white-rgb), 0.2);
          }

        }

        &__socials {
          ul.nav {
            li.nav-item {

              a.nav-link {
                border: none;
              }
            }
          }
        }

      }
    }

    .bilic-article-template__hero {
      background: var(--theme-black);
      justify-content: center;

      .bilic-article-template__curve {
        display: none !important;
      }

      @media only screen and (max-width: 760px) {
        // padding-block-end: 2rem;
        padding-block: 16rem 2rem;
      }

      @media only screen and (min-width: 1400px) {
        min-height: 85vh;
        padding-block: 28rem 2rem;
      }

      &__background {
        // position: absolute;
        // width: 100%;
        // opacity: .6;
        // background-position: 50%;
        // background-size: 1090px;
        // overflow: hidden;
        // pointer-events: none;

        &-training {
          object-fit: contain;
          width: 33%;
          position: absolute;
          right: 80px;
          top: 136px;
          background: rgba(255, 255, 255, 0.01);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 24px;
          box-shadow: inset 0 0 0 8px #ffffff08;
          padding: 8px;

          @media screen and (max-width: 760px) {
            display: none;
          }

          @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            width: 44%;
            right: -86px;
            bottom: -117px;
            top: initial;
          }


          @media only screen and (min-width: 1400px) {
            width: 56%;
            right: -220px;
            top: 180px;
          }

          &::after {
            background: linear-gradient(180deg, #05050700 0%, #050507db 70.48%, #050507 82.36%);
            content: "";
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 60;
          }

        }

        &--neo {
          width: 60%;
          inset: auto 25% -250px auto;
        }
      }

      &__text {
        display: flex;
        flex-direction: column;

        .line-border {
          --border-width: 1px;
          --border-color: #ffffff26;
          height: 100%;
          width: 92%;
          position: absolute;
          display: flex;
          justify-content: center;
          z-index: 50000;

          @media screen and (max-width: 760px) {
            width: 88%;
          }

          &-one,
          &-three {
            border-block-start: var(--border-width) dashed var(--border-color);
            height: 1px;

            @media screen and (max-width: 760px) {
              inset-inline-start: 6px;
            }
          }

          &-one {
            inset-block-start: 4px;

            @media screen and (max-width: 760px) {
              inset-block-start: 0;
            }
          }

          &-three {
            inset-block-start: 84px;

            @media screen and (max-width: 760px) {
              inset-block-start: 39px;
            }
          }

          &-two,
          &-four {
            width: 86%;
            height: 95px;
            inset-block-start: 0px;
          }

          &-two {
            border-inline-start: var(--border-width) dashed var(--border-color);

            @media screen and (max-width: 760px) {
              inset-inline-start: 3px;
            }
          }

          &-four {
            border-inline-end: var(--border-width) dashed var(--border-color);

            @media screen and (max-width: 760px) {
              inset-inline-end: 38px;
            }
          }

        }

        small {
          background: radial-gradient(circle farthest-side, #5cc23a0f, rgba(255, 255, 255, 0)),
            radial-gradient(circle at 140%, #4fd918 23%, #fff);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          -webkit-text-fill-color: transparent;
        }

        h1 {
          --_fs: 6.4rem;
          font-size: var(--_fs);
          line-height: calc(var(--_fs) * 1.2);
          letter-spacing: -.025em;
          // padding-block: 1.2rem 1.2rem;
          // max-width: 940px;
          color: var(--white);
          overflow: hidden;
          min-height: 72px;
          white-space: nowrap;
          position: relative;
          width: 100%;
          margin: 0;

          &>span {
            position: absolute;
            z-index: 200;

            span {
              background: linear-gradient(180deg, #fff 22.5%, rgba(255, 255, 255, 0.7) 100%);
              -webkit-background-clip: text;
              background-clip: text;
              color: transparent;
              -webkit-text-fill-color: transparent;
            }
          }

          @media screen and (max-width: 760px) {
            font-size: 4rem;
            line-height: 64px;
            text-align: left;
            justify-content: flex-start;
            align-items: center;
            min-height: 40px;
            max-width: 100%;
          }

          &:nth-child(2) {
            // padding-block: 1.2rem;
            // height: 84px;

            @media screen and (max-width: 760px) {
              height: 40px;
            }
          }


        }

        p {
          --_fs: 1.8rem;
          font-size: var(--_fs);
          max-width: 612px;
          line-height: calc(var(--_fs)*1.5);
          color: rgba(var(--white-rgb), 0.7);
          margin-block: 1.6rem 3.6rem;
          font-weight: 500;

          @media screen and (max-width: 760px) {
            --_fs: 1.62rem;
            text-align: left;
            margin-block-start: 1.2rem;
            line-height: calc(var(--_fs)*1.6);
          }

          div {
            @media screen and (max-width: 760px) {
              text-align: left !important;

            }

          }

        }
      }

      &__image {
        transition: all 1.2s ease-in;

        @media screen and (max-width: 760px) {
          min-height: 250px;
        }

        img {
          position: relative;
          z-index: 50;
          margin-block-start: 5.2rem;
          background: rgba(255, 255, 255, .01);
          border: 1px solid rgba(255, 255, 255, .1);
          border-radius: 24px;
          box-shadow: inset 0 0 0 8px #ffffff08;
          padding: 8px;
          height: 100%;

          @media screen and (max-width: 760px) {
            // margin-block-start: 1.2rem;
          }
        }

        &::before {
          background: linear-gradient(180deg, #05050700 0%, #050507db 70.48%, #050507 82.36%);
          content: "";
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 60;
        }

        // &::after {
        //   background: linear-gradient(180deg, #05050700 0%, #050507db 70.48%, #050507f5 82.36%);
        //   content: "";
        //   display: block;
        //   height: 100%;
        //   left: 0;
        //   position: absolute;
        //   top: 0;
        //   width: 100%;
        //   z-index: 60;
        // }
      }

      &__links {
        @media screen and (max-width: 760px) {
          margin-inline: 9.4rem;
          width: 100%;
          margin-block-end: 4rem;
        }

        &__btn {
          --_fs: 1.6rem;
          color: var(--white);
          background: linear-gradient(to bottom, #2c9a19, #2c9a19);
          border: 1px solid #68ba384a;
          border-radius: 32px;
          box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.302),
            0 1px 1px rgba(0, 0, 0, 0.18),
            inset 0 1px rgba(255, 255, 255, 0.2),
            inset 0 -1px rgba(0, 0, 0, 0.2);
          cursor: pointer;
          font-size: var(--_fs);
          font-weight: 500;
          font-family: var(--font-primary);
          line-height: 1.6rem;
          min-height: 52px;
          padding: 1.2rem 2.8rem;
          backdrop-filter: blur(6px);


          &:hover {
            color: var(--white);
          }

          &-alt {
            background: transparent;
            border: none;
          }
        }
      }

    }


    .bilic-article-template__content {
      background: var(--theme-black);
      padding-block-start: 4rem;
      padding-block-end: 0;

      @media screen and (max-width: 760px) {
        padding-block-start: 0.4rem;
      }

      &__solutions {
        background: var(--theme-black);
        margin-block: 6rem 0;
        position: relative;




        &__matrix {
          opacity: .4;

          @media screen and (max-width: 760px) {
            display: none;
          }

          &>* {
            position: absolute;
          }

          &-one {
            width: 9px;
            left: 108px;
            top: -186px;

          }

          &-two {
            width: 10px;
            left: 48px;
            top: -131px;

          }

          &-three {
            width: 10px;
            left: 76px;
            top: -231px;
          }
        }

        header {
          h4 {
            max-width: 470px;
          }
        }

        &__list {
          gap: 2rem;
          isolation: isolate;

          &__item {
            background: #05080300;
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 20px;
            padding-block: 2.4rem;
            padding-inline: 2.4rem;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto 1fr;
            gap: 1.2rem;
            color: #fff;
            position: relative;

            &::before,
            &::after {
              content: '';
              position: absolute;
              z-index: 930;
              width: 0.6px;
              height: 90px;
              background-image: linear-gradient(177deg, rgba(118, 110, 245, 0), #68ba38 35%, #2b9a1a 65%, rgba(45, 169, 245, 0));
              opacity: .95;
              top: 33%;
            }

            &::before {
              left: 0%;
            }

            &::after {
              right: 0%;
            }

            figure {
              margin: 0;

              img {
                border: none;
                width: 14%;
                margin-block: 0.4rem;
              }
            }

            &__card {
              position: relative;
              z-index: 200;

              h3 {
                --_fs: 2.2rem;
                font-size: var(--_fs);
                background: linear-gradient(180deg, #ffffffe0 22.5%, rgba(255, 255, 255, 0.7) 100%);
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
                -webkit-text-fill-color: transparent;
              }

              p {
                --_fs: 1.5rem;
                font-size: var(--_fs);
                line-height: calc(var(--_fs) * 1.48);
                color: rgba(var(--white-rgb), 0.6);
                margin-block-start: 0.8rem;
              }
            }
          }
        }

        &-wrap {
          @media only screen and (min-width: 1400px) {
            padding-block: 2rem;
            padding-inline: 4rem;
          }
        }
      }

      &__tagline {
        // min-height: 95vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-block-start: 4rem;
        position: relative;


        @media screen and (max-width: 760px) {
          padding-block-start: 8rem;
          padding-inline: 1.2rem;
          width: 100%;
          overflow: hidden;
        }




        &::after {
          content: '';
          display: inline-flex;
          width: 100%;
          height: 1px;
          background-image: radial-gradient(circle at 10%, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.19) 50%, rgba(255, 255, 255, 0));
          inset-block-end: -44px;
          position: absolute;
        }


        header {
          padding-block-end: 5.6rem;
          // flex-direction: column;
          align-items: flex-start;
          text-align: center;

          h2 {
            --_font-size: 4.8rem;
            font-size: var(--_font-size);
            line-height: calc(var(--_font-size)*1.2);
            font-family: var(--font-primary);
            letter-spacing: -.025em;
            margin-bottom: 0.8rem;
            // max-width: 240px;
            background-image: linear-gradient(0deg, dimgray 0%, white 100%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            -webkit-text-fill-color: transparent;
            text-align: center;

          }

          p {
            --_fs: 1.92rem;
            font-size: var(--_fs);
            max-width: 534px;
            line-height: calc(var(--_fs) * 1.6);
            color: rgba(var(--white-rgb), 0.7);
          }
        }
      }

      &__features {
        margin: 6.4rem 0 12rem;
        position: relative;
        z-index: 60;

        &-wrap {
          @media screen and (max-width: 760px) {
            padding: 1rem 2.4rem;
          }
        }

        &__list {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 3rem;
          justify-items: center;

          @media screen and (max-width: 760px) {
            grid-template-columns: 1fr;
            gap: 1rem 0.4rem;
          }

          &__item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            h6 {
              line-height: 36px;
              font-size: 2.2rem;
              margin-bottom: 0.4rem;
              max-width: 336px;
              --_fs: 2.2rem;
              font-size: var(--_fs);
              background: linear-gradient(180deg, #ffffffe0 22.5%, rgba(255, 255, 255, 0.7) 100%);
              -webkit-background-clip: text;
              background-clip: text;
              color: transparent;
              -webkit-text-fill-color: transparent;
              opacity: 0.8;
            }

            img {
              width: 17%;
              margin-bottom: 2rem;
            }

            p {
              --_fs: 1.64rem;
              font-size: var(--_fs);
              line-height: calc(var(--_fs) * 1.48);
              color: rgba(var(--white-rgb), 0.5);
              margin-block-start: 0.8rem;
              max-width: initial;
            }

            a {
              display: flex;
              align-items: center;
              gap: 0.8rem;
              padding-top: 2.8rem;
              width: 92%;
              justify-content: flex-end;
              font-size: 1.8rem;
              text-decoration: none;
              color: rgba(var(--white-rgb), 0.6);

              i {
                color: var(--theme-primary)
              }

            }
          }
        }
      }



      &__howto {
        padding-block: 12rem 20.8rem;

        @media screen and (max-width: 760px) {
          padding-block-end: 0.8rem;
        }

        &__header {
          transform: translateY(-100px);
          opacity: 0;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-block-end: 4rem;

          @media screen and (max-width: 760px) {
            align-items: flex-start;
            text-align: left;
            margin-block-end: 1.2rem;
          }


          h2 {
            background: linear-gradient(90deg, #44962d, #5cc23a 25.17%, #4fd918 50%, #4fd918 73.09%, #688b20);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-block-end: 1.2rem;
          }

          h4 {
            --_font-size: 4.8rem;
            font-size: var(--_font-size);
            line-height: calc(var(--_font-size)*1.2);
            font-family: var(--font-heading);
            letter-spacing: -.025em;
            margin-bottom: 1.8rem;
            max-width: 640px;
            background-image: linear-gradient(0deg, dimgray 0%, white 100%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            -webkit-text-fill-color: transparent;
          }

          p {
            max-width: 630px;
            --_fs: 1.6rem;
            font-size: var(--_fs);
            line-height: calc(var(--_fs) * 1.6);
            color: rgba(var(--white-rgb), 0.7);
            margin-block-start: 0.4rem;
            margin-block-end: 4.8rem;

            @media screen and (max-width: 760px) {
              margin-block-end: 3.6rem;
            }
          }
        }

        &__steps {
          // background-color: #0c0c13;
          // background-color: #070b08;
          background-color: #060907;
          border: 1px solid rgba(255, 255, 255, 0.08);
          border-radius: 24px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 32px;
          position: relative;
          overflow: hidden;
          min-height: 540px;
          height: 100%;
          isolation: isolate;

          &::before {
            position: absolute;
            inset: 0;
            z-index: -20;
            opacity: 1;
            mix-blend-mode: overlay;
            content: "";
            background-image: url(../images/noise.webp);
          }

          h6 {
            --_fs: 2.8rem;
            font-size: var(--_fs);
            background: linear-gradient(180deg, #fff 22.5%, rgba(255, 255, 255, 0.7) 100%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            -webkit-text-fill-color: transparent;
            margin-block: 1.2rem 0.6rem;

          }

          p {
            --_fs: 1.6rem;
            font-size: var(--_fs);
            line-height: calc(var(--_fs) * 1.48);
            color: rgba(var(--white-rgb), 0.7);
            margin-block-start: 0.4rem;
          }

          figure {
            display: flex;
            width: 100%;
            justify-content: center;

            img {
              width: 88%;
              position: absolute;
            }

          }


          &--one {
            figure {
              img {
                width: 76%;
                bottom: -36px;

                @media screen and (max-width: 760px) {
                  width: 92%;
                  bottom: -15px;
                }
              }
            }
          }

          &--two {
            figure {
              img {
                width: 60%;
                bottom: -180px;

                @media screen and (max-width: 760px) {
                  width: 88%;
                  bottom: -145px;
                }
              }
            }
          }

          &--three {
            figure {
              img {
                width: 64%;
                bottom: -120px;

                @media screen and (max-width: 760px) {
                  width: 90%;
                  bottom: -95px;
                }
              }
            }
          }

          &--four {
            figure {
              img {
                width: 58%;
                bottom: -66px;

                @media screen and (max-width: 760px) {
                  width: 90%;
                  bottom: -86px;
                }
              }
            }
          }



        }

      }

      &__teaser {
        padding-block: 2rem 8rem;
        position: relative;

        header {
          padding-block: 12rem 8rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          z-index: 30;
          text-align: center;

          @media screen and (max-width: 760px) {
            align-items: flex-start;
            text-align: left;
            padding-inline: 2rem;
            padding-block: 4rem 4rem;
          }

          &::after {
            content: '';
            display: inline-flex;
            width: 100%;
            height: 1px;
            background-image: radial-gradient(circle at 10%, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.19) 50%, rgba(255, 255, 255, 0));
            inset-block-start: 6px;
            position: absolute;
          }

          h4 {
            --_ff: var(--font-heading);
            --_fs: 4.8rem;
            font-size: var(--_fs);
            line-height: calc(var(--_fs) * 1.3);
            font-family: var(--_ff);
            font-weight: 600;
            background-image: linear-gradient(0deg, dimgray 0%, white 100%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            -webkit-text-fill-color: transparent;
            max-width: 440px;
            position: relative;
          }

          p {
            --_fs: 1.92rem;
            font-size: var(--_fs);
            max-width: 534px;
            line-height: calc(var(--_fs) * 1.6);
            color: rgba(var(--white-rgb), 0.7);
          }
        }

        &__illustration {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          position: relative;
          background-color: #060907;
          /* background: linear-gradient(252deg,#ffffff,#50d828 60.44%); */
          background-image: linear-gradient(#4fed5e14, #4fed5e03);
          border-radius: 42px;
          padding: 30px 36px;
          overflow: hidden;
          min-height: 500px;
          height: 100%;
          isolation: isolate;
          overflow: hidden;

          header {
            padding: 0.4rem 0 0.6rem;
            border-block-end: 0.8px solid #ffffff5e;
            width: 100%;
            justify-content: flex-start;
            text-align: left;
            align-items: flex-start;

            &::after {
              content: '';
              display: none;
            }

            h6 {
              --_font-size: 2.8rem;
              font-size: var(--_font-size);
              line-height: calc(var(--_font-size)*1.2);
              font-family: var(--font-heading);
              letter-spacing: -.025em;
              margin-bottom: 0.4rem;
              max-width: 640px;
              background-image: linear-gradient(0deg, dimgray 0%, white 100%);
              -webkit-background-clip: text;
              background-clip: text;
              color: transparent;
              -webkit-text-fill-color: transparent;
            }
          }

          p {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            gap: 2.8rem;
            color: rgba(var(--white-rgb), 0.7);
            --_fs: 1.6rem;
            font-size: var(--_fs);
            line-height: calc(var(--_fs) * 1.5);
            margin: 0;
            padding-block: 3rem;
          }

          &::before {
            position: absolute;
            inset: 0;
            z-index: -20;
            opacity: 0.2;
            mix-blend-mode: overlay;
            content: "";
            background-image: url(../images/noise.webp);
          }

          footer {
            display: flex;
            gap: 1.2rem;
            border-block-start: 0.8px solid #ffffff5e;
            width: 100%;

            button {
              &.btn {
                --_fs: 1.2rem;
                color: var(--white);
                border: none;
                border-radius: 32px;
                cursor: not-allowed;
                font-size: var(--_fs);
                font-weight: 500;
                font-family: var(--font-primary);
                line-height: 1.5rem;
                min-height: 40px;
                padding: 1.2rem 2rem;
                pointer-events: none;

                &-hacker {
                  background: linear-gradient(to bottom, #D64137, #9A2F18);

                }

                &-neutral {
                  background: transparent;
                  border: 0.4px solid #ffffff5e;
                }
              }
            }
          }

          &::after {
            content: '';
            margin: 4px;
            height: 100%;
            overflow: hidden;
            background-image: url(../images/dots.svg);
            background-repeat: repeat;
            background-size: 30px 30px;
            border-radius: 16px;
            -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 1), transparent);
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-type: alpha;
            position: absolute;
            inset: 0;
            z-index: -1;
          }
        }

        &__text {
          h3 {
            --width: 500px;
            --_fs: 4rem;
            font-size: var(--_fs);
            line-height: calc(var(--_fs)*1.2);
            color: var(--white);
            background: linear-gradient(180deg, #fff 22.5%, rgba(255, 255, 255, 0.7) 100%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            -webkit-text-fill-color: transparent;
            margin-bottom: 2.4rem;
            max-width: var(--width);

            span {
              background: linear-gradient(90deg, #44962d, #5cc23a 25.17%, #4fd918 50%, #4fd918 73.09%, #688b20);
              -webkit-background-clip: text;
              background-clip: text;
              color: transparent;
              -webkit-text-fill-color: transparent;
            }
          }

          p {
            --clr-white: #ffffff94;
            color: var(--clr-white);
            --_width: 512px;
            --_fs: 1.8rem;
            font-size: var(--_fs);
            line-height: calc(var(--_fs)*1.5);
            max-width: var(--_width);
          }
        }

        &-wrap {
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;
          position: relative;

          @media screen and (max-width: 760px) {
            grid-template-columns: 1fr;
            border-radius: 24px;
            padding-inline: 2.8rem !important;
          }

        }

        &-lefttext {
          background: var(--theme-black);
        }

        &-righttext {

          .bilic-article-template__content__teaser-wrap {
            &::after {
              -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), transparent);
            }
          }

          img {
            right: initial;
            max-width: 39%;
            left: 110px;

            @media screen and (max-width: 760px) {
              position: static;
              max-width: 100%;
              margin-bottom: 2rem;
            }

            @media only screen and (min-width: 1400px) {
              right: initial;
              left: 92px;
              max-width: 34%;
              top: -1px;
            }

          }

        }


      }

      &__benefit {
        padding-block: 12rem;

        header {
          h2 {
            --_font-size: 4.8rem;
            font-size: var(--_font-size);
            line-height: calc(var(--_font-size)*1.2);
            font-family: var(--font-heading);
            letter-spacing: -.025em;
            margin-bottom: 1.8rem;
            background: radial-gradient(circle farthest-side, #5cc23a0f, rgba(255, 255, 255, 0)), radial-gradient(circle at 140%, #4fd918 23%, #fff);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            -webkit-text-fill-color: transparent;
          }

          p {
            max-width: 480px;
            --_fs: 1.6rem;
            font-size: var(--_fs);
            line-height: calc(var(--_fs) * 1.6);
            color: rgba(var(--white-rgb), 0.7);
            margin-block-start: 0.4rem;
            margin-block-end: 5.2rem;

          }
        }

      }

      &__testimonial {
        padding-block: 12rem;

        header {
          h6 {
            --_font-size: 3.2rem;
            font-size: var(--_font-size);
            line-height: calc(var(--_font-size)*1.2);
            font-family: var(--font-heading);
            letter-spacing: -.025em;
            margin-bottom: 1.8rem;
            background-image: linear-gradient(0deg, dimgray 0%, white 100%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            -webkit-text-fill-color: transparent;
          }

          p {
            max-width: 480px;
            --_fs: 1.6rem;
            font-size: var(--_fs);
            line-height: calc(var(--_fs) * 1.6);
            color: rgba(var(--white-rgb), 0.7);
            margin-block-start: 0.4rem;
            margin-block-end: 3.2rem;
          }
        }

        &__list {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 3rem;
          justify-items: center;

          @media screen and (max-width: 760px) {
            grid-template-columns: 1fr;
            gap: 1rem 0.4rem;
          }

          a {
            text-decoration: none;
          }
        }

        &__card {
          background: #264b2636;
          border-radius: 2.4rem;
          padding-block: 2rem;
          padding-inline: 2rem;

          img {
            width: 48px;
            height: 48px;
          }

          span {
            h6 {
              --_font-size: 2rem;
              font-size: var(--_font-size);
              line-height: calc(var(--_font-size)*1.2);
              font-family: var(--font-heading);
              color: var(--white);
              margin: 0;
              padding: 0;
            }

            p {
              --_fs: 1.64rem;
              font-size: var(--_fs);
              line-height: calc(var(--_fs) * 1.48);
              color: rgba(var(--white-rgb), 0.5);
              max-width: 342px;
              margin: 0;
              padding: 0;
            }
          }

          p {
            --_fs: 1.64rem;
            font-size: var(--_fs);
            line-height: calc(var(--_fs) * 1.48);
            color: rgba(var(--white-rgb), 0.5);
            margin-block: 1.2rem 1.2rem;
            max-width: initial;
          }

        }
      }


      &__cta {
        padding-block: 12rem;

        &__wrap {
          background: #0f150f;
          min-height: 36rem;
          border-radius: 2.4rem;
        }

        h6 {
          --_font-size: 4.8rem;
          font-size: var(--_font-size);
          line-height: calc(var(--_font-size)*1.2);
          font-family: var(--font-heading);
          letter-spacing: -.025em;
          margin-bottom: 1.8rem;
          background-image: linear-gradient(0deg, dimgray 0%, white 100%);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          -webkit-text-fill-color: transparent;
        }

        p {
          max-width: 630px;
          --_fs: 1.6rem;
          font-size: var(--_fs);
          line-height: calc(var(--_fs) * 1.6);
          color: rgba(var(--white-rgb), 0.7);
          margin-block-start: 0.4rem;
          margin-block-end: 3.2rem;
        }
      }


    }

    .bilic-landing__banner__manifest__advisors {
      @media only screen and (min-width: 1400px) {
        padding-block: 3.2rem 4.8rem;
      }

      @media screen and (max-width: 760px) {
        padding-inline: 2rem;
      }

      h4 {
        font-size: 1.6rem;
        font-family: var(--font-primary);
        margin-bottom: 2.4rem;
        font-weight: 400;
        color: var(--white);
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 760px) {
          align-items: flex-start;
        }
      }

      &__slide {
        gap: 32px;
        width: 100%;

        @media screen and (max-width: 760px) {
          justify-content: flex-start;
        }

        img {
          width: 10%;
          opacity: .7;

          @media screen and (max-width: 760px) {
            width: 24%;
          }
        }
      }
    }

    .bilic-landing__number {
      background-image: radial-gradient(#62ff920f 1px, #050508 1px);
      background-size: 20px 20px;
      padding-block: 4.4rem;
      padding-inline: 3.6rem;

      &-wrap {
        background-image: none;
        background-size: initial;
        margin-block: 6rem 12rem;
      }

      &__item {

        h4 {
          font-family: var(--font-heading-grotesk-italic);
          color: #c8c8d8;
          font-size: 2.4rem;
          display: flex;
          align-items: center;
          background-image: linear-gradient(0deg, dimgray 0%, #ffffffb5 100%);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          -webkit-text-fill-color: transparent;

          span {
            font-size: 60px;
            font-family: var(--font-heading-grotesk);
            margin-right: 4px;
            align-self: flex-end;
          }
        }

        p {
          max-width: 266px;
          --_fs: 1.56rem;
          font-size: var(--_fs);
          line-height: calc(var(--_fs)*1.5);
          color: rgba(var(--white-rgb), 0.5);
        }
      }

    }
  }
}


.c-glow {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 1;
  opacity: 1;

  @media screen and (max-width: 760px) {
    overflow: hidden;
  }


}

.c-glow__wrap {
  width: 100%;
  position: relative;
  height: 100%;
  filter: blur(150px);

  // &--one {
  //   filter: blur(175px);
  // }

  // &--two {
  //   filter: blur(175px);
  // }
}

.c-glow--one {
  position: absolute;
  border-radius: 362.53px;
  transform: rotate(279deg);
  mix-blend-mode: overlay;
  top: -32%;
  left: 37%;
  width: 400px;
  height: 400px;
  aspect-ratio: 683/492;
  background: linear-gradient(267.56deg, #27f515 22.11%, #03e40e 40.73%, #03e40e 54.78%, #03e40e 69.88%, #03e40e 89.56%);
}


.c-glow--two {
  position: absolute;
  border-radius: 362.53px;
  transform: rotate(279deg);
  mix-blend-mode: overlay;
  top: -32%;
  left: 37%;
  width: 400px;
  height: 400px;
  aspect-ratio: 683/492;
  background: linear-gradient(267.56deg, #27f515 22.11%, #03e40e 40.73%, #03e40e 54.78%, #03e40e 69.88%, #03e40e 89.56%);
}


.bilic__glow {
  position: absolute;
  z-index: 930;
  width: 17%;
  height: 0.2px;
  background-image: linear-gradient(90deg, rgba(118, 110, 245, 0), #68ba38 35%, #c6d55c 65%, rgba(45, 169, 245, 0));
  opacity: .95;
  left: 41%;
  top: -25px;
}

// html.lenis {
//   height: auto;
// }

// .lenis.lenis-smooth {
//   scroll-behavior: auto;
// }

// .lenis.lenis-smooth [data-lenis-prevent] {
//   overscroll-behavior: contain;
// }

// .lenis.lenis-stopped {
//   overflow: hidden;
// }

// .lenis.lenis-scrolling iframe {
//   pointer-events: none;
// }

body {
  background-color: var(--theme-black) !important;
}


.calendly-badge-widget {
  z-index: 6000;

  .calendly-badge-content {
    background: transparent !important;
    color: rgb(255, 255, 255) !important;
    border: 1px solid #fff;
  }
}