:root {
  --font-primary: "work sans", Arial, sans-serif;
  --font-heading: "PolySans-Median",
    Arial,
    sans-serif;
  --font-heading-grotesk: "PolySans-Median",
    Arial,
    sans-serif;
  --font-heading-grotesk-italic: "PolySans-SlimItalic",
    Arial,
    sans-serif;
  --white: #fff;
  --black: #000;
  --white-rgb: 255, 255, 255;
  --black-rgb: 0, 0, 0;
  --theme-black: #050507;
  --theme-black-rgb: 4, 8, 2;
  --theme-primary: #51dc00;
  --theme-primary-rgb: 104, 186, 56;
  // --theme-primary-rgb: 202, 252, 86;
  --theme-accent: #ffe254;
  --theme-accent-rgb: 2555, 226, 84;
  --green: #21ce99;
}