.bilic-article-template {
  &-contact {

    .bilic-global-header__inner {
      .bilic-global-header__navigation {
        &__link {

          ul,
          li {
            list-style: none;
          }

          a,
          button {
            color: var(--white);
          }
        }
      }
    }

    .bilic-article-template__hero {
      display: none;
    }

  }
}