.c-legal {
  &__banner {
    background-color: var(--theme-primary);
    background-image: url(./images/print-left.svg),
      url(./images/print-right.svg);
    background-position: -1% 100%,
      101% 100%;
    background-repeat: no-repeat;
    background-size: 35%,
      37%;
    min-height: 44rem;
    display: flex;
    padding-top: 13rem;

    @media screen and (max-width: 760px) {
      min-height: 56rem;
      background-position: -23% 74%,
        101% 83%;
      background-size: 48%,
        55%;
    }

    h3 {
      font-size: 12px;
      line-height: 120%;
      font-weight: 600;
      letter-spacing: 2px;
      text-transform: uppercase;
      background: #fff;
      min-width: 60px;
      min-height: 40px;
      padding: 1.6rem 2.4rem;
      border-radius: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h5 {
      font-size: 4rem;
      line-height: 60px;
      font-family: var(--font-heading-grotesk);
      color: var(--theme-black);
      margin: 20px 0 20px;
      max-width: 700px;

      @media screen and (max-width: 760px) {
        font-size: 3.6rem;
        line-height: 50px;
      }
    }

    h6 {
      font-size: 12px;
      line-height: 120%;
      font-weight: 400;
      letter-spacing: 2px;
      text-transform: capitalize;
      margin: 8px 0;
    }

    p {
      font-size: 1.3rem;
      max-width: 350px;
      line-height: 1.6;
    }
  }

  &__list {
    padding: 4rem 0 15rem;

    .container {
      max-width: 1260px;
    }

    .grid-list {
      margin-bottom: 4rem;

      &-wrap {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        // grid-auto-rows: 240px;
        gap: 5rem 3rem;

        @media only screen and (max-width: 720px) {
          // display: flex;
          // overflow-x: scroll;
          // padding-left: 2rem;
          // flex: 1 1 100%;
        }
      }
    }

    &__header {
      h5 {
        font-family: 'Gilroy-medium',
          sans-serif;
        font-size: 1.8rem;
        margin: 16px 0 52px;
        color: #0f1218;

      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      // align-items: center;
      // text-align: center;


      h4 {
        font-family: var(--font-primary);
        font-size: 1.8rem;
        margin: 24px 0 8px;
        color: var(--theme-black);
      }

      h6 {
        margin: 2px 0 8px;
        opacity: .8;
        font-size: 16px;
        line-height: 32px;
        font-weight: 300;
        max-width: 570px;
      }

      p {
        opacity: .86;
        font-size: 1.44rem;
        line-height: 24px;
        font-family: var(--font-primary);
        min-height: 100px;
      }

      ul {
        li {
          a {
            text-decoration: none;
            padding: 1rem 1rem 1rem 0;

            i {
              font-size: 29px;
              color: #0f1218;
              background: #e4ffd4;
              width: 50px;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100%;
              padding: 0;
              margin: 0;
            }
          }
        }
      }

      &__image {
        // filter: grayscale(1);
        display: flex;
        width: 150px;
        height: 150px;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        border-radius: 50%;
        background: #fafafa;

        img {
          width: 100%;
          position: absolute;
          // top: -42px;
          // left: 4px;

          &.paco {
            top: -35px;
            left: -5px;
          }

          &.sami {
            top: -51px;
            left: -3px;
          }

          &.machi {
            top: -19px;
            left: 3px;
          }
        }
      }
    }

    &-wrap {
      background: #fff;
      margin-top: -18rem;
      padding: 4rem 6rem;
    }
  }
}