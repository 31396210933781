.bilic-article-template {
  // .bilic-global-header {
  //   &__inner {
  //     .mobile-nav {

  //       &-button {
  //         button {

  //           &:hover,
  //           &:focus,
  //           &:active {
  //             outline: none;
  //             box-shadow: none;
  //           }
  //         }
  //       }

  //       &-menu {
  //         .offcanvas {
  //           &-top {
  //             height: 85vh;
  //             background: rgba(0, 0, 0, 0.83);
  //             backdrop-filter: saturate(182%) blur(12px);

  //           }

  //           &-header {
  //             h5 {
  //               a {
  //                 color: rgba(var(--white-rgb), .8);
  //                 font-size: 2.4rem;
  //                 font-family: "Comfortaa",
  //                   sans-serif;
  //               }
  //             }

  //             button {
  //               position: absolute;
  //               background: transparent;
  //               background: transparent;
  //               font-size: 2.8rem;
  //               color: #ccc !important;

  //               &:hover,
  //               &:focus,
  //               &:active {
  //                 outline: none;
  //                 box-shadow: none;
  //               }
  //             }
  //           }

  //           &-body {
  //             ul {
  //               list-style: none;
  //               margin-top: 1.2rem;

  //               a {
  //                 color: rgba(var(--white-rgb), .8);
  //                 font-size: 2.0rem;
  //                 text-decoration: none;
  //               }


  //               li {
  //                 margin-bottom: 2.4rem;

  //                 h5 {
  //                   color: rgba(var(--white-rgb), .6);
  //                 }

  //                 ul {
  //                   li {
  //                     margin-bottom: 1.2rem;
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }

  //     }

  //   }

  // }

  &__hero {
    background: #e2fadc;
    min-height: 75vh;
    padding: 14rem 0 12rem;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 760px) {
      min-height: 68vh;
      padding: 12rem 0.8rem 8rem;
    }

    @media only screen and (min-width: 1400px) {
      padding: 20rem 0 16rem;
    }

    &__text {
      position: relative;
      z-index: 30;

      h6 {
        font-size: 12px;
        line-height: 120%;
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
        background: #fff;
        width: 180px;
        height: 40px;
        border-radius: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      h5 {
        font-size: 6.5rem;
        line-height: 78px;
        font-family: var(--font-heading-grotesk);
        color: var(--theme-black);
        padding: 24px 0 16px;
        max-width: 740px;

        span {
          background: linear-gradient(90deg, #44962d, #5cc23a 25.17%, #208b4a 50%, #1c703d 73.09%, #208b4a);
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        @media screen and (max-width: 760px) {
          font-size: 3.6rem;
          line-height: 50px;
        }
      }

      p {
        font-size: 2.0rem;
        max-width: 600px;
        line-height: 3.4rem;
      }
    }

    &__image {
      position: relative;
      z-index: 1000;
      min-height: 700px;

      img {
        width: 100%;
      }

    }

    &__links {
      display: flex;
      justify-content: flex-start;
      margin: 2rem 0 1.2rem;

      .btn {
        border: 1px solid var(--theme-black);
        padding: 0.5rem 1rem;
        min-height: 2.4rem;
        margin-top: .4rem;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1.6rem;
        min-width: 160px;
        height: 52px;
        border-radius: 30px;

        @media screen and (max-width: 760px) {
          min-width: 160px;
        }

      }

      &__btn {
        background: var(--theme-black);
        color: var(--white);

        &-alt {
          background: transparent;
          color: var(--theme-black);
        }
      }


    }



    &__background {
      z-index: 10;

      &-right {
        object-fit: contain;
        width: 72%;
        position: absolute;
        right: -170px;
        top: 20px;

        @media screen and (max-width: 768px) {
          width: 166%;
          right: -275px;
          top: 156px;
        }

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
          width: 119%;
          right: -250px;
          top: 84px;
        }

      }

      &-left {
        object-fit: contain;
        width: 41%;
        position: absolute;
        left: -141px;
        top: 124px;

        @media screen and (max-width: 760px) {
          display: none;
        }
      }
    }
  }

  &__curve {
    display: none;
  }

  &__content {
    padding: 6rem 0 0;
    background: #fff;

    @media screen and (max-width: 760px) {
      padding: 5.2rem 0 0;
    }

    .btn {
      border: 1px solid var(--theme-primary);
      padding: 0.5rem 1rem;
      min-width: 160px;
      min-height: 48px;
      margin-top: 2.8rem;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      background: var(--theme-primary);
    }

    &__heading {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding-block: 2rem;
      padding-inline: 4rem;

      @media screen and (max-width: 760px) {
        align-items: flex-start;
        text-align: left;
        padding-inline: 2rem;

      }

      h4 {
        --_ff: var(--font-heading);
        --_fs: 4.8rem;
        font-size: var(--_fs);
        line-height: calc(var(--_fs) * 1.2);
        font-family: var(--_ff);
        font-weight: 600;
        background-image: linear-gradient(0deg, dimgray 0%, white 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
        max-width: 440px;
        position: relative;
        // transition: all .6s ease-in;

        span {
          background: linear-gradient(90deg, #44962d, #5cc23a 25.17%, #4fd918 50%, #4fd918 73.09%, #688b20);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      p {

        max-width: 560px;
        --_fs: 2rem;
        font-size: var(--_fs);
        line-height: calc(var(--_fs) * 1.6);
        color: rgba(var(--white-rgb), 0.7);
        margin-block-start: 1.6rem;
        margin-block-end: 0.4rem;
      }
    }



    &__tagline {
      header {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 30;
        text-align: center;

        @media screen and (max-width: 760px) {
          text-align: left;
        }

        h4 {
          line-height: 76px;
          font-size: 5.6rem;
          margin-bottom: 1.6rem;
          max-width: 700px;

          @media screen and (max-width: 760px) {
            font-size: 4rem;
            line-height: 56px;
          }

          span {
            background: linear-gradient(90deg, #44962d, #5cc23a 25.17%, #208b4a 50%, #1c703d 73.09%, #208b4a);
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        p {
          font-size: 2rem;
          max-width: 840px;
          line-height: 32px;
        }
      }
    }

    &__features {
      margin: 6.4rem 0 12rem;
      position: relative;
      z-index: 60;

      &-wrap {
        @media screen and (max-width: 760px) {
          padding: 1rem 2.4rem;
        }
      }

      &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 3rem;
        justify-items: center;

        @media screen and (max-width: 760px) {
          grid-template-columns: 1fr;
          gap: 1rem 0.4rem;
        }

        &__item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          h6 {
            line-height: 36px;
            font-size: 2.2rem;
            margin-bottom: 0.4rem;
            max-width: 336px;
          }

          img {
            width: 17%;
            margin-bottom: 2rem;
          }

          p {
            font-size: 1.6rem;
            max-width: 380px;
            line-height: 28px;
          }

          a {
            display: flex;
            align-items: center;
            gap: 0.8rem;
            padding-top: 2.8rem;
            width: 92%;
            justify-content: flex-end;
            font-size: 1.8rem;
            text-decoration: none;
            color: var(--theme-black);

            i {
              color: var(--theme-primary)
            }

          }
        }
      }
    }

    &__teaser {
      padding: 14.2rem 0 12rem;
      position: relative;

      @media screen and (max-width: 760px) {
        padding: 7.2rem 0 !important;
      }


      &-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;

        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
        }
      }

      &-lefttext {
        // background: #f6fdf9;
        background: #F9FBFC;
      }

      &__text {
        h3 {
          margin-bottom: 2.4rem;
          max-width: 640px;
          font-size: 60px;
          line-height: 72px;

          @media screen and (max-width: 760px) {
            font-size: 36px;
            line-height: 48px;
          }

          span {
            background: linear-gradient(90deg, #44962d, #5cc23a 25.17%, #208b4a 50%, #1c703d 73.09%, #208b4a);
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        p {
          font-size: 1.7rem;
          max-width: 490px;
          line-height: 34px;
          margin-bottom: 2rem;
        }

      }
    }

    &__solutions {
      // margin: 8rem 0 0;
      background: #f6fdf9;

      &-wrap {
        padding: 8rem 0;
        border-radius: 20px;

        @media screen and (max-width: 760px) {
          padding: 1rem;
        }

        @media only screen and (min-width: 1400px) {
          padding: 8rem;
        }
      }

      &__header {
        h4 {
          margin-bottom: 3.6rem;
          font-size: 3.6rem;
          max-width: 300px;
          line-height: 52px;
          color: var(--theme-black);
        }
      }

      &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2.8rem;
        justify-content: center;
        align-items: stretch;

        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
          gap: 1rem 0.4rem;
        }

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
          overflow: scroll;
          grid-template-columns: repeat(auto-fill, minmax(240px, 45%));
          gap: 0.8rem;
        }


        &__item {
          background: #fff;
          border-radius: 12px;
          padding: 2.8rem;

          @media screen and (max-width: 760px) {
            padding: 1.6rem 0.8rem;
          }

          img {
            width: 100%;
            margin-bottom: 2.4rem;
          }

          h3 {
            font-size: 1.7rem;
            line-height: 28px;
          }

          p {
            font-size: 1.4rem;
            line-height: 3.2rem;
            color: var(--theme-black);
            margin-top: 12px;
          }
        }
      }
    }

    &__cta {
      padding: 3rem 0;

      header {
        display: flex;
        flex-direction: column;

        h4 {
          line-height: 44px;
          font-size: 3.2rem;
          margin: 3.2rem 0 .4rem;
          max-width: 760px;
        }

        p {
          font-size: 2rem;
          max-width: 448px;
          line-height: 34px;
          margin-bottom: 2rem;
        }
      }

      &-foot {
        padding: 8rem 0 3rem;
        color: #fff;
        background: var(--black);
      }
    }

    // &__cta-foot {
    //   background: var(--black);
    //   padding: 8rem 0;

    //   @media only screen and (max-width: 575px) {
    //     padding: 3rem 0;
    //     background-position: -18% 104%,
    //       120% 93%;
    //   }

    //   &__header {
    //     display: flex;
    //     justify-content: center;
    //     flex-direction: column;
    //     align-items: center;

    //     h2 {
    //       font-size: 4.8rem;
    //       font-family: var(--font-heading-grotesk);
    //       color: var(--theme-black);
    //       margin-bottom: 3.6rem;
    //       line-height: 58px;
    //       letter-spacing: -0.0525rem;
    //       max-width: 620px;

    //       @media only screen and (max-width: 575px) {
    //         font-size: 2.4rem;
    //         line-height: 32px;
    //         max-width: 300px;
    //         margin-bottom: 2.6rem;
    //       }
    //     }

    //     a.btn {
    //       background: #fff;
    //       color: #0d1528;
    //       font-size: 2rem;
    //       min-width: 180px;

    //       i {
    //         margin-right: 1rem;
    //       }

    //     }
    //   }
    // }

    &__background-curve {
      position: absolute;
      left: 0px;
      right: 0;
      z-index: 20;
      width: 100%;
      margin: 0 auto;

      &-one {
        top: -240px;
        height: 60%;
      }

      &-two {
        transform: scaleY(-1);
        bottom: -232px;
        height: 61%;
      }

    }

    &__post {
      h2 {
        max-width: 920px;
        font-size: 3.2rem;
        line-height: 44px;
        margin-bottom: 2.8rem;
      }

      h3 {
        font-family: var(--font-primary);
        font-size: 22px;
        font-weight: 400;
        line-height: 32px;
        margin-bottom: 28px;
      }

      article {
        font-family: var(--font-primary);
        font-weight: 400;
        margin: 32px 0 40px;
        max-width: 948px;


        h4 {
          font-size: 2rem;
          line-height: 44px;
          margin-bottom: 0.6rem;
        }

        h5 {
          font-size: 2rem;
          line-height: 44px;
          margin-bottom: 0.6rem;
        }

        h6 {
          font-size: 1.8rem;
          line-height: 44px;
          margin-bottom: 0.6rem;
          font-weight: 400;
          font-family: var(--font-primary);
        }

        ul {
          margin-left: 2rem;

          li {
            font-size: 20px;
            line-height: 36px;
            margin-bottom: 16px;
          }
        }

        p {
          font-size: 18px;
          line-height: 32px;
          margin: 16px 0 28px;
        }
      }
    }

    &__form {
      background: var(--theme-black);
      padding-block: 4rem 8rem;

      header {
        border-block-start: 0.4px solid rgba(var(--white-rgb), 0.3);
        padding-block: 2.8rem 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        h4 {
          --font-size: 4.4rem;
          max-width: 800px;
          --line-height: calc(var(--font-size)*1.2);
          font-family: var(--font-heading-grotesk);
          color: rgba(var(--white-rgb), 0.88);
          font-size: var(--font-size);
          margin: 1.6rem 0 0.8rem;
          text-align: center;
          // max-width: 400px;
        }

        p {
          font-size: 1.8rem;
          max-width: 582px;
          line-height: 1.5;
          color: rgba(var(--white-rgb), 0.8);
          font-family: var(--font-primary);
          text-align: center;
        }
      }

      form {

        textarea {
          min-height: 120px;
        }

        .form-control {
          height: 52px;
          background: transparent;
          color: rgba(var(--white-rgb), 0.8);
          border: 0.4px solid rgba(var(--white-rgb), 0.3);
          border-radius: 1.2rem;
          font-size: 1.6rem;
          padding-inline-start: 1rem;

          &::placeholder {
            color: rgba(var(--white-rgb), 0.4);
            font-size: 1.6rem;
            padding-inline-start: 1rem;
            font-family: var(--font-primary);
          }
        }

        button.btn {
          color: var(--theme-black) !important;
          font-weight: 700;
        }
      }

      .form-success {
        display: flex;
        gap: 2rem;

        i {
          font-size: 6rem;
          color: var(--theme-primary);
        }

        p {
          font-size: 2.0rem;
          max-width: 590px;
          line-height: 3.5rem;
          color: rgba(var(--white-rgb), 0.8);
          font-family: var(--font-primary);
          margin-bottom: 0.4rem;

          &:first-of-type {
            color: var(--theme-primary);
          }
        }
      }
    }
  }
}