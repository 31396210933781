.bilic-article-template {
  &-funds {

    .bilic-global-header__inner {
      border-bottom: 1px solid rgba(var(--theme-primary-rgb), 0.1);

      .bilic-global-header__navigation {
        &__link {

          a,
          button {
            color: var(--white);
          }
        }
      }
    }

    .bilic-article-template__hero {
      background: var(--theme-black);
      min-height: 45vh;

      @media only screen and (min-width: 1400px) {
        padding: 15rem 0 10rem;
      }


      &__text {

        h5 {
          color: var(--white);

          @media screen and (max-width: 760px) {
            font-size: 5.2rem;
            line-height: 64px;
          }
        }

        p {
          font-size: 2.0rem;
          max-width: 590px;
          line-height: 3.5rem;
          color: rgba(var(--white-rgb), .8);
        }
      }

      &__background {
        &-right {
          opacity: .2;
          width: 48%;

        }

        &-left {
          opacity: .3;
        }
      }
    }

    .bilic-article-template__content__post {
      a {
        color: var(--theme-primary);
      }
    }

  }
}