.bilic-global-header {
  padding: .8rem 0;
  z-index: 100;
  position: absolute;
  width: 100%;
  top: 60px;
  left: 0;
  right: 0;

  @media screen and (max-width: 760px) {
    top: 50px;
  }

  .container,
  .container-lg,
  .container-xl {
    @media only screen and (min-width: 1400px) {
      max-width: 1280px;
    }
  }

  .mobile-nav {

    &-button {
      button i {
        font-size: 2rem;
        color: #020302;
      }
    }

    &-menu {
      .offcanvas-header button {
        position: absolute;
        top: 78px;
        right: 20px;
      }

    }

  }

  &__navigation {
    &__logo {
      font-family: "Comfortaa",
        sans-serif;
      font-weight: 700;
      font-size: 2.4rem;
      color: currentColor;

      img {
        padding-top: 1.2rem;
      }
    }

    &__nav {
      justify-content: flex-start;

      @media screen and (max-width: 760px) {
        justify-content: center;
        margin: 1.2rem 0 .8rem;
      }

    }

    &__link {
      list-style-type: none;
      margin-right: 2.8rem;
      min-height: 50px;
      display: flex;

      @media screen and (max-width: 760px) {
        margin-bottom: 1.6rem;
      }

      a,
      button {
        font-family: var(--font-primary);
        font-size: 1.6rem;
        font-weight: 500;
        color: rgba(var(--black-rgb), 0.8);
        display: flex;
        align-items: center;
        gap: 0.4rem;
        background: transparent;
        border: none;
        appearance: none;
        text-decoration: none;

        &:hover {
          color: #000;
        }
      }

      button.btn-dropdown {
        padding: 0 !important;
      }
    }

    &__btn {
      display: flex;
      gap: 4px;

      .btn {
        height: 42px;
        background: transparent;
        font-family: var(--font-heading);
        font-size: 1.4rem;
        color: var(--theme-black);
        cursor: pointer;
        transition: background-color 150ms linear,
          color 150ms linear;
        margin-left: 0.8rem;
        border-radius: 40px;
        border: 1px solid var(--theme-black);

        &.link-cta {
          background: var(--theme-black);
          color: var(--theme-primary);
        }
      }
    }

    //  &__btn {
    //    .btn {
    //      background-image: linear-gradient(90deg, #a96ffc, #8c41f7 25.17%, #cb814c 50%, #f2bd86 73.09%, #bbdef2);
    //      color: rgba(var(--white-rgb), 1);
    //      transition: all 0.3s ease 0s;
    //      position: relative;
    //      width: 140px;
    //      height: 40px;
    //      margin-left: 1.6rem;

    //      span {
    //        background: #130a1e;
    //        width: 99%;
    //        height: 98%;
    //        position: absolute;
    //        display: flex;
    //        white-space: nowrap;
    //        align-items: center;
    //        justify-content: center;
    //        border-radius: 31px;
    //      }
    //    }
    //  }

    &__socials {
      ul.nav {
        li.nav-item {
          margin-left: 0.8rem;

          a.nav-link {
            background: transparent;
            display: flex;
            height: 36px;
            width: 36px;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            padding: 0;
            border: 1px solid var(--theme-black);

            i {
              color: var(--theme-black);
              font-size: 1.6rem;
            }
          }
        }
      }
    }




  }

  &__inner {
    top: 0;
    z-index: 2000;
    min-height: 72px;
    background-color: var(--theme-black);

    // @media screen and (max-width: 760px) {
    //   height: 140px;
    // }

    .bilic-global-header__navigation__logo {
      color: var(--white);
    }

    .bilic-global-header__navigation__link {

      a {
        color: var(--white);
        text-decoration: none;
      }
    }

    .bilic-global-header__navigation__socials ul.nav li.nav-item a.nav-link {
      background: transparent;
      // border: 1px solid rgba(var(--white-rgb), .8);
      border: 1px solid #cff1c5;

      i {
        color: rgba(var(--white-rgb), .8);
      }
    }

    .bilic-global-header__navigation__btn .btn {
      font-family: var(--font-heading);
      color: rgba(var(--white-rgb), .8);
      border: 1px solid rgba(var(--white-rgb), .8);
    }

    .bilic-global-header__navigation__link a {
      color: rgba(var(--white-rgb), 1);

      &:hover {
        color: var(--theme-primary);
      }
    }


    .mobile-nav {

      &-button {
        button i {
          font-size: 2rem;
          color: #7aad6e;
        }
      }

      &-menu {
        .offcanvas-header button {
          position: absolute;
          top: 13px;
          right: 20px;
        }

      }

    }

  }
}

.mobile-nav {

  &-button {
    button {

      &:hover,
      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
      }
    }
  }

  &-menu {
    .offcanvas {
      &-top {
        height: 50vh;
        background: rgba(0, 0, 0, 0.83);
        backdrop-filter: saturate(182%) blur(12px);

      }

      &-header {
        h5 {
          a {
            color: rgba(var(--white-rgb), .8);
            font-size: 2.4rem;
            font-family: "Comfortaa",
              sans-serif;
          }
        }

        button {
          position: absolute;
          background: transparent;
          background: transparent;
          font-size: 2.8rem;
          color: #ccc !important;

          &:hover,
          &:focus,
          &:active {
            outline: none;
            box-shadow: none;
          }
        }
      }

      &-body {
        ul {
          list-style: none;
          margin-top: 1.2rem;

          a {
            color: rgba(var(--white-rgb), .8);
            font-size: 2.0rem;
            text-decoration: none;
          }


          li {
            margin-bottom: 2.4rem;

            h5 {
              color: rgba(var(--white-rgb), .6);
            }

            ul {
              li {
                margin-bottom: 1.2rem;
              }
            }
          }
        }
      }
    }
  }

}